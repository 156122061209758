import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ActivityStatistics from './components/ActivityStatistics';
import AddUserGroups from './components/AddUserGroups';
import BulkAddUsers from './components/BulkAddUsers';
import EditPresentation from './components/EditPresentation';
import EditProduct from './components/EditProduct';
import EditUser from './components/EditUser';
// import APIKeys from './components/APIKeys';
import Home from './components/Home';
import LeftSideBarMenu from './components/LeftSideBarMenu';
import ManagePresentations from './components/ManagePresentations';
import ManageProducts from './components/ManageProducts';
import ManageUserGroup from './components/ManageUserGroup';
import ManageUserGroupsPres from './components/ManageUserGroupsPres';
import ManageUsers from './components/ManageUsers';
import ModalAuthorization from './components/ModalAuthorization';
import RESTInfo from './components/RESTInfo';
import Statistic from './components/Statistic';
import TopBar from './components/TopBar';
import { handleLogout } from './reducers/user';
import ROUTES from './routes';
function App(props) {
  const { isMCP } = props;
  return (
    <div className='App'>
      <TopBar />
      {isMCP ? null : <LeftSideBarMenu />}
      <ModalAuthorization />
      <main>
        {isMCP ? <MCPComponents /> : <AdminComponents />}
        {/* <Route exact path={'/chose-icons'} component={ChoseIconWidget} /> */}
      </main>
    </div>
  );
}

const MCPComponents = () => (
  <Route exact path={ROUTES.homePage.link} component={ManagePresentations} />
);
const AdminComponents = () => (
  <>
    <Route exact path={ROUTES.homePage.link} component={Home} />
    {/* <Route exact path={ROUTES.apiKeys.link} component={APIKeys} /> */}
    <Route exact path={ROUTES.presentations.link} component={ManagePresentations} />
    <Route exact path={ROUTES.manageUsers.link} component={ManageUsers} />
    <Route exact path={ROUTES.manageProducts.link} component={ManageProducts} />
    <Route exact path={ROUTES.addUserGroups.link} component={AddUserGroups} />
    <Route
      exact
      path={ROUTES.editPresentation.link + '/:presentationId'}
      component={EditPresentation}
    />
    <Route exact path={ROUTES.RESTInfo.link} component={RESTInfo} />
    <Route exact path={ROUTES.statistic.link} component={Statistic} />
    <Route exact path={ROUTES.activityStatistics.link} component={ActivityStatistics} />
    <Route exact path={ROUTES.editUser.link + '/:userId'} component={EditUser} />
    <Route exact path={ROUTES.editProduct.link + '/:productId'} component={EditProduct} />
    <Route exact path={ROUTES.manageUserGroup.link + '/:groupId'} component={ManageUserGroup} />
    <Route exact path={ROUTES.bulkAddUser.link} component={BulkAddUsers} />
    <Route
      exact
      path={ROUTES.manageUserGroupPres.link + '/:groupId'}
      component={ManageUserGroupsPres}
    />
  </>
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleLogout
    },
    dispatch
  );

const mapStateToProps = ({ user: { isMCP } }) => ({
  isMCP
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
