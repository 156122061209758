import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import React from 'react';

export default function UserListFilter({
  title,
  items,
  numberOfChecked,
  classes,
  checked,
  handleToggle,
  handleToggleAll
}) {
  function sortUsersByFirstName(user1, user2) {
    if (user1.firstName > user2.firstName) return 1;
    if (user1.firstName < user2.firstName) return -1;
    return 0;
  }
  const [filterValue, setFilterValue] = React.useState('');
  const itemsToRender = items
    .filter(
      (item) =>
        item.firstName.includes(filterValue) ||
        item.secondName.includes(filterValue) ||
        item.lastName.includes(filterValue) ||
        item.email.includes(filterValue)
    )
    .sort(sortUsersByFirstName);
  const handleChange = (e) => {
    setFilterValue(e.target.value);
  };
  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <TextField
        label='Filter'
        name='filter'
        className={classes.filter}
        variant='outlined'
        autoComplete='off'
        value={filterValue}
        onChange={handleChange}
      />
      <List className={classes.list} dense component='div' role='list'>
        {itemsToRender.map((user) => {
          const labelId = `transfer-list-all-item-${user.id}-label`;

          return (
            <ListItem key={user.id} role='listitem' button onClick={handleToggle(user)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.map(({ id }) => id).indexOf(user.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={<p>{`name: ${user.firstName} ${user.secondName} ${user.lastName}`}</p>}
                secondary={`email: ${user.email}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
}
