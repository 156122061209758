import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PresentationPreview from '../PresentationPreview';
import { SERVER_URL } from '../../config';

export default function PresentationListFilter({
  title,
  items,
  numberOfChecked,
  classes,
  checked,
  handleToggle,
  handleToggleAll
}) {
  function sortPresentationsByName(pres1, pres2) {
    if (pres1.name > pres2.name) return 1;
    if (pres1.name < pres2.name) return -1;
    return 0;
  }
  const [filterValue, setFilterValue] = React.useState('');
  const itemsToRender = items
    .filter(item => item.name.includes(filterValue))
    .sort(sortPresentationsByName);
  const handleChange = e => {
    setFilterValue(e.target.value);
  };
  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <TextField
        label='Filter'
        name='filter'
        className={classes.filter}
        variant='outlined'
        autoComplete='off'
        value={filterValue}
        onChange={handleChange}
      />
      <List className={classes.list} dense component='div' role='list'>
        {itemsToRender.map(presentation => {
          const labelId = `transfer-list-all-item-${presentation.id}-label`;
          return (
            <ListItem
              key={presentation.id}
              role='listitem'
              button
              onClick={handleToggle(presentation)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.map(({ id }) => id).indexOf(presentation.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
                <PresentationPreview
                  classes={classes.image}
                  id={presentation.id}
                  serverUrl={SERVER_URL}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={<p>{`${presentation.name}`}</p>} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
}
