import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import axios from 'axios';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../config';
import routes from '../routes';
import UserListFilter from './CustomLists/UserListFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 400
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  header: {
    padding: theme.spacing(2)
  },
  filter: {
    width: '100%'
  },
  item: {
    minWidth: 500
  }
}));

function not(a, b) {
  return a.filter(({ id }) => b.map(({ id }) => id).indexOf(id) === -1);
}

function intersection(a, b) {
  return a.filter(({ id }) => b.map(({ id }) => id).indexOf(id) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function ManageUserGroup({
  token,
  match: {
    params: { groupId }
  }
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [currGroup, setCurrGroup] = React.useState(null);
  const [isChangedGroup, setIsChangedGroup] = React.useState(false);
  useEffect(() => {
    async function initUserList() {
      requestCurrentGroup(groupId)(setCurrGroup).then(() =>
        requestAllUsers().then(requestUsersByGroupId)
      );
    }
    initUserList();
  }, []); // eslint-disable-line

  const requestUsersByGroupId = (allUsers) => {
    return axios
      .get(SERVER_URL + '/api/user-group/' + groupId, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((r) => {
        return r;
      })
      .then((result) => result.data)
      .then((users) => {
        if (users.length) {
          setLeft(not(allUsers, users));
          setRight(users);
        } else {
          setLeft(allUsers);
        }
      });
  };

  const requestCurrentGroup = (id) => (cb) =>
    axios
      .get(SERVER_URL + '/api/get-user-group/' + id, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((response) => cb(response.data));

  const handleSaveUsersInGroup = (rightUsers) => () => {
    return axios
      .post(
        SERVER_URL + '/api/user-group/' + groupId,
        {
          userIds: rightUsers.map((user) => user.id)
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(setIsChangedGroup(false))
      .catch(console.log);
  };

  function requestAllUsers() {
    return axios
      .get(SERVER_URL + '/api/user', {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((r) => {
        return r;
      })
      .then((result) => result.data)
      .then((allUsers) => allUsers);
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (user) => () => {
    const currentIndex = checked.map(({ id }) => id).indexOf(user.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setIsChangedGroup(true);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setIsChangedGroup(true);
  };

  const customListProps = {
    numberOfChecked: numberOfChecked,
    classes: classes,
    handleToggleAll: handleToggleAll,
    handleToggle: handleToggle,
    checked: checked
  };

  return (
    <>
      <Link to={routes.addUserGroups.link}>
        <Button>
          <Avatar>
            <ArrowBack />
          </Avatar>
        </Button>
      </Link>
      <Typography className={classes.header}>
        {currGroup ? 'Group name : ' + currGroup.name : null}
      </Typography>
      <Grid container spacing={2} justify='center' alignItems='flex-start' className={classes.root}>
        <Grid item className={classes.item}>
          <UserListFilter title='Все пользователи вне группы' items={left} {...customListProps} />
        </Grid>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Button
              variant='outlined'
              size='small'
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label='move selected right'
            >
              <ArrowForwardIcon />
            </Button>
            <Button
              variant='outlined'
              size='small'
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label='move selected left'
            >
              <ArrowBackIcon />
            </Button>
            <Button
              disabled={!isChangedGroup}
              variant='outlined'
              onClick={handleSaveUsersInGroup(right)}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.item}>
          <UserListFilter title='Все пользователи в группе' items={right} {...customListProps} />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ user }) => ({
  token: user.token
});

export default connect(mapStateToProps)(ManageUserGroup);
