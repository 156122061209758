/* eslint-disable eqeqeq */
export function getUniq(primaryProp, excludeProps = '') {
  const lacmus = new Map();
  const equal = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
  return function ({ [primaryProp]: uniq, [excludeProps]: _, ...props }) {
    const sUniq = uniq.toString();
    if (!lacmus.has(sUniq)) {
      lacmus.set(sUniq, props);
      return true;
    }
    if (lacmus.has(sUniq)) {
      const same = lacmus.get(sUniq);
      return !equal(same, props);
    }
  };
}

export function filterByIncludeHour({ from, to, currentHour }) {
  return currentHour >= from && currentHour < to;
}

export function specifyHours(el) {
  const startDate = new Date(`${el.entertime}`);
  const startHour = startDate.getHours();
  switch (true) {
    case startHour >= 8 && startHour < 12:
      return {
        ...el,
        period: '08:00-12:00'
      };
    case startHour >= 12 && startHour < 16:
      return {
        ...el,
        period: '12:00-16:00'
      };
    case startHour >= 16 && startHour < 20:
      return {
        ...el,
        period: '16:00-20:00'
      };
    default:
      return el;
  }
}

/**
 * '08:00' -> 8
 * '09:30' -> 9
 */
export function timeHHMMtoHourNumber(time) {
  return Number(time.split(':')[0]);
}

export function timeStampToHour(timeStamp) {
  const date = new Date(timeStamp);
  return date.getHours();
}

export function getDoneVisits(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['doneVisits']
        ? Number(acc[el.region][el.user.email]['doneVisits']) + 1
        : 1
      : 1
    : 1;
}

export function getRelevantVisits(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['relevantVisits'] !== undefined && el.isRelevance
        ? Number(acc[el.region][el.user.email]['relevantVisits']) + 1
        : Number(acc[el.region][el.user.email]['relevantVisits'])
      : el.isRelevance
      ? 1
      : 0
    : el.isRelevance
    ? 1
    : 0;
}

export function getDurationFrom8To12(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['durationFrom8To12'] !== undefined &&
        el.period === '08:00-12:00' &&
        el.isRelevance
        ? Number(acc[el.region][el.user.email]['durationFrom8To12']) + Number(el.duration)
        : Number(acc[el.region][el.user.email]['durationFrom8To12'])
      : el.period === '08:00-12:00' && el.isRelevance
      ? Number(el.duration)
      : 0
    : el.period === '08:00-12:00' && el.isRelevance
    ? Number(el.duration)
    : 0;
}

export function getDurationFrom12To16(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['durationFrom12To16'] !== undefined &&
        el.period === '12:00-16:00' &&
        el.isRelevance
        ? Number(acc[el.region][el.user.email]['durationFrom12To16']) + Number(el.duration)
        : Number(acc[el.region][el.user.email]['durationFrom12To16'])
      : el.period === '12:00-16:00' && el.isRelevance
      ? Number(el.duration)
      : 0
    : el.period === '12:00-16:00' && el.isRelevance
    ? Number(el.duration)
    : 0;
}

export function getDurationFrom16To20(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['durationFrom16To20'] !== undefined &&
        el.period === '16:00-20:00' &&
        el.isRelevance
        ? Number(acc[el.region][el.user.email]['durationFrom16To20']) + Number(el.duration)
        : Number(acc[el.region][el.user.email]['durationFrom16To20'])
      : el.period === '16:00-20:00' && el.isRelevance
      ? Number(el.duration)
      : 0
    : el.period === '16:00-20:00' && el.isRelevance
    ? Number(el.duration)
    : 0;
}

export function getCountVisitsFrom8To12(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['countVisitsFrom8To12'] !== undefined &&
        el.period === '08:00-12:00' &&
        el.isRelevance
        ? Number(acc[el.region][el.user.email]['countVisitsFrom8To12']) + 1
        : Number(acc[el.region][el.user.email]['countVisitsFrom8To12'])
      : el.period === '08:00-12:00' && el.isRelevance
      ? 1
      : 0
    : el.period === '08:00-12:00' && el.isRelevance
    ? 1
    : 0;
}

export function getCountVisitsFrom12To16(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['countVisitsFrom12To16'] !== undefined &&
        el.period === '12:00-16:00' &&
        el.isRelevance
        ? Number(acc[el.region][el.user.email]['countVisitsFrom12To16']) + 1
        : Number(acc[el.region][el.user.email]['countVisitsFrom12To16'])
      : el.period === '12:00-16:00' && el.isRelevance
      ? 1
      : 0
    : el.period === '12:00-16:00' && el.isRelevance
    ? 1
    : 0;
}

export function getCountVisitsFrom16To20(acc, el) {
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email]['countVisitsFrom16To20'] !== undefined &&
        el.period === '16:00-20:00' &&
        el.isRelevance
        ? Number(acc[el.region][el.user.email]['countVisitsFrom16To20']) + 1
        : Number(acc[el.region][el.user.email]['countVisitsFrom16To20'])
      : el.period === '16:00-20:00' && el.isRelevance
      ? 1
      : 0
    : el.period === '16:00-20:00' && el.isRelevance
    ? 1
    : 0;
}

// Говно мутабельное
export function getAllSpecializations(allSpecializations, el) {
  !allSpecializations.includes(el.specialization) &&
    el.specialization &&
    allSpecializations.push(el.specialization);
}

export function getViewedPresentations(acc, specialization, product, presentation) {
  return specialization && acc[specialization]
    ? acc[specialization][product]
      ? acc[specialization][product].presentations
        ? acc[specialization][product].presentations.includes(presentation)
          ? acc[specialization][product].presentations
          : acc[specialization][product].presentations.concat(presentation)
        : [presentation]
      : [presentation]
    : [presentation];
}

export function getSlidesDurations(acc, specialization, product, durations) {
  return acc[specialization]
    ? acc[specialization][product]
      ? acc[specialization][product].durations
        ? acc[specialization][product].durations + durations
        : durations
      : durations
    : durations;
}

export function getSpecializationsInfo(acc, el) {
  const { events } = el;
  return acc[el.region]
    ? acc[el.region][el.user.email]
      ? acc[el.region][el.user.email].specializations
        ? mergeObjects(acc[el.region][el.user.email].specializations, events)
        : { ...events }
      : { ...events }
    : { ...events };
}

export function getAllProductsPerSpecialization(allProducts, el) {
  const { events } = el;
  if (events === null) return;
  Object.keys(events).forEach((specialization) => {
    if (specialization === 'null' || specialization === '') return;
    if (allProducts[specialization]) {
      allProducts[specialization] = [
        ...new Set([
          ...allProducts[specialization],
          ...Object.keys(events[specialization]).filter((el) => el != 'undefined' && el != '')
        ])
      ];
    } else {
      allProducts[specialization] = Object.keys(events[specialization]).filter(
        (el) => el != 'undefined' && el != ''
      );
    }
  });
}

/* 
 {
   [product]: {
     ...
   }
 }
*/

// function isObject(item) {
//   return item && typeof item === 'object' && !Array.isArray(item);
// }

// function isArray(item) {
//   return item && Array.isArray(item);
// }

// function isNumber(item) {
//   return item && typeof item === 'number';
// }

function filterObjectUndefinedKeys(obj) {
  Object.keys(obj).forEach((key) => {
    if (key === 'undefined') delete obj[key];
    if (obj[key] && typeof obj[key] === 'object') filterObjectUndefinedKeys(obj[key]);
  });
  return obj;
}

// Временно так
function mergeObjects(source, target) {
  const result = { ...filterObjectUndefinedKeys(source) };

  for (const specialization in target) {
    if (source.hasOwnProperty(specialization) && specialization) {
      for (const product in target[specialization]) {
        if (source[specialization].hasOwnProperty(product) && product) {
          for (const productData in target[specialization][product]) {
            if (source[specialization][product].hasOwnProperty(productData) && productData) {
              if (typeof source[specialization][product][productData] === 'number') {
                source[specialization][product][productData] +=
                  target[specialization][product][productData];
              }
              // console.log(productData);
              if (Array.isArray(source[specialization][product][productData])) {
                source[specialization][product][productData] = [
                  ...new Set([
                    ...source[specialization][product][productData],
                    ...target[specialization][product][productData]
                  ])
                ];
              }
            }
          }
        } else {
          if (product) {
            result[specialization][product] = { ...target[specialization][product] };
          }
        }
      }
    } else {
      // Specializations
      result[specialization] = { ...target[specialization] };
    }
  }
  return result;
}

export const getTodaySplitForMaterialUI = () => {
  let today = new Date();
  today = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
    today.getDate()
  ).padStart(2, '0')}`;
  return today;
};

export const fillDictionarys = (
  userMetrics,
  presMetrics,
  userData,
  presData,
  userGroupLinkData,
  presentationProductLinkData,
  productsData,
  userGroupData,
  userDataForEachFunc
) => {
  const presList = {};
  const userList = {};
  const userCalls = {};
  const presCalls = {};
  const userGroups = {};
  const presProducts = {};
  const productList = {};
  const userGroupList = {};

  presData.forEach((presInfo) => (presList[presInfo.id] = presInfo.name));

  userData.forEach(userDataForEachFunc(userList));

  userMetrics.forEach((userMetric) => (userCalls[userMetric.MetricId] = userMetric.UserId));
  presMetrics.forEach((presMetric) => (presCalls[presMetric.MetricId] = presMetric.PresentationId));

  productsData.forEach((product) => (productList[product.id] = product.name));
  userGroupData.forEach((userGroup) => (userGroupList[userGroup.id] = userGroup.name));

  userGroupLinkData.forEach((userGroupLink) =>
    userGroups[userGroupLink.UserId]
      ? userGroups[userGroupLink.UserId].push(userGroupList[userGroupLink.UserGroupId])
      : (userGroups[userGroupLink.UserId] = [userGroupList[userGroupLink.UserGroupId]])
  );
  presentationProductLinkData.forEach((presentationProductLink) =>
    presProducts[presentationProductLink.PresentationId]
      ? presProducts[presentationProductLink.PresentationId].push(
          productList[presentationProductLink.ProductId]
        )
      : (presProducts[presentationProductLink.PresentationId] = [
          productList[presentationProductLink.ProductId]
        ])
  );

  return [
    presList,
    userList,
    userCalls,
    presCalls,
    userGroups,
    presProducts,
    productList,
    userGroupList
  ];
};

export const getAllData = async (SERVER_URL, axios, token) => {
  const options = { headers: { Authorization: `Bearer ${token}` } };
  return await Promise.all([
    axios.get(SERVER_URL + '/rest/Metrics/all', options),
    axios.get(SERVER_URL + '/rest/UserMetricsLink/all', options),
    axios.get(SERVER_URL + '/rest/PresentationMetricsLink/all', options),
    axios.get(SERVER_URL + '/rest/User/all', options),
    axios.get(SERVER_URL + '/rest/Presentation/all', options),
    axios.get(SERVER_URL + '/rest/Product/all', options),
    axios.get(SERVER_URL + '/rest/UserGroup/all', options),
    axios.get(SERVER_URL + '/rest/UserGroupLink/all', options),
    axios.get(SERVER_URL + '/rest/PresentationProductLink/all', options)
  ]);
};
