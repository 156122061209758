export const OPEN_MENU = 'left-side-menu/OPEN_MENU';
export const CLOSE_MENU = 'left-side-menu/CLOSE_MENU';

const initState = {
  isOpen: false,
  active: 0
};

export default (state = initState, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return {
        ...state,
        isOpen: true
      };

    case CLOSE_MENU:
      return {
        ...state,
        isOpen: false
      };

    default:
      return state;
  }
};

export const handleOpenMenu = () => (dispatch) => {
  dispatch({
    type: OPEN_MENU
  });
};

export const handleCloseMenu = () => (dispatch) => {
  dispatch({
    type: CLOSE_MENU
  });
};
