import axios from 'axios';
import jexcel from 'jexcel';
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../node_modules/jexcel/dist/jexcel.css';
import { SERVER_URL } from '../config';
import UserAPI from './API/user.ts';
import UserGroupAPI from './API/userGroup.ts';

const options = {
  columns: [
    { title: 'firstName', width: 200 },
    { title: 'secondName', width: 200 },
    { title: 'lastName', width: 200 },
    { title: 'email', width: 200 },
    { title: 'password', width: 200 },
    { title: 'groups', width: 200 }
    // { title: 'user already exist', width: 100 }
  ]
};

function BulkAddUsers({ token, userAPI, userGroupAPI }) {
  const [data, setData] = useState([['', '', '', '', '', '']]);
  const [numOfAddRow, setNumOfAddRow] = useState(1);
  const tableRef = useRef(null);

  useEffect(() => {
    const onafterchanges = () => setData(tableRef.current.jexcel.getData());
    jexcel(tableRef.current, { data, ...options, onafterchanges });
  }, []); // eslint-disable-line

  function ckeckUserAlreadyExist(data) {
    Promise.all(data.map((row, index) => sendExitsRequest(row[3], index))).then();
  }

  function sendExitsRequest(email, index) {
    return axios
      .get(SERVER_URL + '/api/has-user-on-this-server/' + email)
      .then(({ data }) => {
        setRowExist({ data: 'EXITS', index });
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setRowExist({ data: 'OK', index });
        } else {
          setRowExist({ data: 'Error', index });
        }
      });
  }

  const updateAllGroups = (groupsObj) => {
    return axios
      .get(SERVER_URL + '/api/user-group', {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((result) => {
        const groupIdByName = result.data.reduce((acc, group) => {
          acc[group.name] = group.id;
          return acc;
        }, {});
        const namesAndIds = Object.entries(groupsObj);
        const promiseArr = namesAndIds.map(([groupName, userIds]) => {
          const groupId = groupIdByName[groupName];
          if (groupId) {
            return userGroupAPI.setUsersInGroup({ groupId, userIds });
          }
          return null;
        });
        return Promise.all(promiseArr);
      });
  };

  function createUsers(data) {
    const allGroupsWithUsers = {};
    const promiseArr = data.map((row) => {
      if (row.every((field, i) => i === 1 || field !== '')) {
        const userGroups = row[5]
          .split(';')
          .filter((el) => el)
          .map((el) => el.trim());
        return userAPI
          .createUser({
            firstName: row[0],
            secondName: row[1],
            lastName: row[2],
            email: row[3],
            password: row[4]
          })
          .then((res) => {
            console.log('kek');
            const userId = res.data.id;
            userGroups.forEach((group) => {
              const groupToUpdate = allGroupsWithUsers[group];
              if (groupToUpdate) {
                groupToUpdate.push(userId);
              } else {
                allGroupsWithUsers[group] = [userId];
              }
            });
          });
      }
      return null;
    });
    Promise.all(promiseArr).then(() => {
      return updateAllGroups(allGroupsWithUsers);
    });
    // .then(() => ckeckUserAlreadyExist(tableRef.current.jexcel.getData()));
  }

  function handleCerateUsers() {
    createUsers(tableRef.current.jexcel.getData());
  }

  function setRowExist({ data, index }) {
    const currData = tableRef.current.jexcel.getData();

    currData[index][6] = data;
    tableRef.current.jexcel.setData(currData);
  }

  function addRow(num = 1) {
    let counter = 0;
    while (counter < num) {
      tableRef.current.jexcel.insertRow();
      counter++;
    }
  }
  function handleAddRow() {
    addRow(numOfAddRow);
  }
  const setRows = ({ target: { value } }) => setNumOfAddRow(value);

  return (
    <div className='bulk-add-users'>
      <div ref={tableRef} className='bulk-add-users--table' />
      <div className='bulk-add-user--add-row-btn'>
        <button onClick={handleAddRow}>add row btn</button>
        <input type='number' value={numOfAddRow} onChange={setRows} />
      </div>
      <button onClick={handleCerateUsers}> create all </button>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  token: user.token,
  userAPI: new UserAPI({ TOKEN: user.token, SERVER_URL }),
  userGroupAPI: new UserGroupAPI({ TOKEN: user.token, SERVER_URL })
});

export default connect(mapStateToProps)(BulkAddUsers);
