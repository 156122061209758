import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { handleCloseMenu, handleOpenMenu } from '../reducers/left-side-menu';
import ROUTES from '../routes';
import DiskSpaceInfo from './DiskSpaceInfo';
import Version from './Vesion';
const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  }
});

const filterRoutesItemByAccess = (user, ROUTES) =>
  Object.keys(ROUTES).map(key => {
    const isRouteForAdmin = ROUTES[key].access.indexOf('admin') !== -1;
    const userIsAdmin = user.isAdmin;
    if (!isRouteForAdmin) {
      return ROUTES[key];
    }
    if (isRouteForAdmin && userIsAdmin) {
      return ROUTES[key];
    }
    return null;
  });

function LeftSideBarMenu(props) {
  const classes = useStyles();
  const sideList = side => (
    <div
      className={classes.list}
      role='presentation'
      onClick={props.handleCloseMenu}
      onKeyDown={props.handleCloseMenu}
    >
      <List>
        {filterRoutesItemByAccess(props.user, ROUTES)
          .filter(item => item)
          .map(({ title, link, icon, hide }, index) => {
            return hide && hide.leftSideMenu ? null : (
              <Link key={title} to={link}>
                <ListItem button key={title}>
                  <ListItemIcon>{icon()}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItem>
              </Link>
            );
          })}
      </List>
      <Version />
      <DiskSpaceInfo />
    </div>
  );

  return (
    <div>
      <Drawer open={props.isOpen} onClose={props.handleCloseMenu}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

const mapStateToProps = ({ leftSideMenu, user }) => ({
  ...leftSideMenu,
  user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleCloseMenu,
      handleOpenMenu
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftSideBarMenu);
