import React from 'react';
import { connect } from 'react-redux';
import { SERVER_URL } from '../config';
import axios from 'axios';
import IconShoppingCart from '@material-ui/icons/ShoppingCart';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import IconEdit from '@material-ui/icons/Edit';
import routes from '../routes';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  header: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'baseline'
  },
  btn: {
    marginLeft: theme.spacing(1)
  },
  filter: {
    marginLeft: theme.spacing(1.5)
  }
}));

const ManageProducts = ({ user }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    productList: null,
    isLoading: false,
    hasError: null
  });

  const [filterMask, setFilterMask] = React.useState('');

  const [name, setName] = React.useState('');

  function handleSetName({ target: { value } }) {
    setName(value);
  }

  function refreshProduct() {
    requestProductList(state, user.token);
  }

  function handleCreateProduct() {
    setState({ ...state, isLoading: true });
    sendRequestCreateProduct(user.token)
      .then(() => refreshProduct())
      .catch((err) => setState({ ...state, hasError: err.message }));
  }

  function sendRequestCreateProduct(token) {
    return axios
      .post(
        SERVER_URL + '/api/products',
        { name },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((result) => result.data);
  }

  function handleSetFilterMask({ target: { value } }) {
    setFilterMask(value);
  }

  function requestProductList(state, token) {
    setState({ ...state, isLoading: true });
    return axios
      .get(SERVER_URL + '/api/products', {
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then((result) => result.data)
      .then((data) => {
        return data;
      })
      .then((productList) => {
        setState({ ...state, productList, isLoading: false });
      })
      .catch((err) => setState({ ...state, hasError: err.message }));
  }

  React.useEffect(() => {
    function initRequestProductList(state) {
      requestProductList(state, user.token);
    }
    initRequestProductList(state);
  }, []); // eslint-disable-line
  return (
    <>
      <TextField
        placeholder='Filter...'
        name='filter'
        value={filterMask}
        onChange={handleSetFilterMask}
        className={classes.filter}
      />
      {state.productList === null ? (
        state.hasError === null ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Typography color='secondary'>{state.hasError}</Typography>
        )
      ) : (
        [
          ...state.productList
            .filter((propduct) => new RegExp(filterMask).test(propduct.name))
            .map(({ id, name }) => (
              <List key={id} className={classes.root}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <IconShoppingCart />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={name} secondary='secondary text' />
                  <Link to={`${routes.editProduct.link}/${id}`}>
                    <Button variant='contained' className={classes.btn}>
                      <Avatar>
                        <IconEdit className={classes.icon} />
                      </Avatar>
                    </Button>
                  </Link>
                </ListItem>
              </List>
            )),
          <Paper key={'paper'} className={classes.paper}>
            <FormCreateProduct
              disableBtn={isNameAlreadyExist(state.productList, name)}
              {...{ name, handleSetName, handleCreateProduct }}
            />
          </Paper>
        ]
      )}
    </>
  );
};

function FormCreateProduct(props) {
  const classes = useStyles();
  const handlePresKey = (event) => {
    if (event.key === 'Enter') {
      props.handleCreateProduct();
      event.preventDefault();
    }
  };
  return (
    <>
      <TextField
        onKeyPress={handlePresKey}
        label='Name'
        value={props.name}
        onChange={props.handleSetName}
        margin='normal'
      />
      <Button
        className={classes.btn}
        disabled={props.disableBtn}
        variant='contained'
        color='primary'
        onClick={props.handleCreateProduct}
      >
        Создать продукт
      </Button>
    </>
  );
}

const mapStateToProps = ({ user }) => ({
  user
});

function isNameAlreadyExist(productList, name) {
  return productList.some((product) => product.name === name);
}

export default connect(mapStateToProps)(ManageProducts);
