import React from 'react';
import { SERVER_URL } from '../config';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import StatisticForm from './StatisticForm';
import { fillDictionarys, getAllData } from './StatisticV2/utils';
import useStatisticForm from '../hooks/useStatisticForm';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: theme.spacing(3)
  },
  date: {
    marginRight: theme.spacing(1)
  }
}));

const Statistic = ({ token }) => {
  const classes = useStyles();

  const btnText = 'Download EXCEL';

  const [
    { dates, handleChangeDates },
    { sessionDurationFilter, handleSetSessionDurationFilter },
    { includeTime, handleSetIncludeTime },
    { isLoading, setLoading },
    { error, setError },
    { testMode, handleChangeTestMode }
  ] = useStatisticForm();

  const writeToXLSX = (presViewData, slideViewData, answersViewData) => {
    setLoading(false);
    try {
      const wb = XLSX.utils.book_new();
      const presData = XLSX.utils.json_to_sheet(presViewData);
      const slideData = XLSX.utils.json_to_sheet(slideViewData);
      const answersData = XLSX.utils.json_to_sheet(answersViewData);
      wb.SheetNames.push('Presentation');
      wb.SheetNames.push('Slides');
      wb.SheetNames.push('Answers');
      wb.Sheets['Presentation'] = presData;
      wb.Sheets['Slides'] = slideData;
      wb.Sheets['Answers'] = answersData;
      XLSX.writeFile(wb, `stat_${dates.from}.xlsx`);
    } catch (e) {
      console.error(e);
    }
  };

  const getPresentationListView = (callsInfo) => {
    const presViewData = callsInfo.map((call) => ({
      Call_id: call.call_id,
      Presentation: call.presentation,
      User: call.user,
      'User email': call.userEmail,
      Groups: call.groups ? call.groups.join(',') : '',
      'HCP ID': call.HCPId,
      'Assigned Products': call.products ? call.products.join(',') : '',
      'Viewed products': call.viewedProducts ? call.viewedProducts.join(', ') : '',
      "Doctor's visit duration (sec)": call.duration,
      "Doctor's visit duration (h:m:s)": call.durationMinutes,
      Entertime: call.entertime
    }));
    return presViewData;
  };

  const getSlidesListView = (callsInfo) => {
    const slideViewData = [];
    callsInfo.forEach((call) =>
      call.slideList.forEach((slide) =>
        slideViewData.push({
          Call_id: call.call_id,
          Presentation: call.presentation,
          User: call.user,
          'User email': call.userEmail,
          'HCP ID': call.HCPId,
          Slide: slide.slide,
          'Duration (sec)': slide.duration,
          Product: slide.product ? slide.product : '',
          Entertime: new Date(slide.enterTime)
        })
      )
    );
    return slideViewData;
  };

  const getAnswersListView = (callsInfo) => {
    const answersViewData = [];
    callsInfo.forEach((call) => {
      return call.events.forEach((event) =>
        answersViewData.push({
          Call_id: call.call_id,
          Presentation: call.presentation,
          User: call.user,
          'User email': call.userEmail,
          'HCP ID': call.HCPId,
          Slide: event.slide,
          Product: event.product,
          Type: event.type,
          Value: event.value,
          Question: event.question ? event.question : '',
          Variants: event.variants ? event.variants : '',
          Entertime: new Date(event.enterTime)
        })
      );
    });
    return answersViewData;
  };

  const secToTime = (duration) => {
    let seconds = Math.floor(duration % 60);
    let minutes = Math.floor((duration / 60) % 60);
    let hours = Math.floor((duration / (60 * 60)) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return `${hours}:${minutes}:${seconds}`;
  };

  const getDuration = (enterTime, exitTime) => ((exitTime - enterTime) / 1000).toFixed(0);

  const getSlideList = (events, presExitTime) => {
    return events
      .filter(
        (event) =>
          event.dataJSON.eventType &&
          event.dataJSON.eventType.toLowerCase() === 'openslide' &&
          event
      )
      .map(({ dataJSON, enterTime }, i, eventsOpenSlide) => {
        const { slide, product } = dataJSON;
        const lastEvent = i === eventsOpenSlide.length - 1;
        const slideExitTime = lastEvent ? presExitTime : eventsOpenSlide[i + 1].enterTime;
        return {
          slide,
          product,
          enterTime,
          duration: getDuration(enterTime, slideExitTime)
        };
      });
  };

  const getViewedProducts = (slideEvents) => {
    const products = slideEvents
      .filter(
        (ev) => ev.dataJSON.eventType === 'openSlide' || ev.dataJSON.eventType === 'openSLide'
      )
      .map(({ dataJSON }) => dataJSON.product)
      .filter((prod) => prod);
    return [...new Set(products)];
  };

  const getEvents = (events, call_id, presentation) =>
    events
      .map(({ enterTime, dataJSON }) => {
        if (dataJSON.eventType === 'openSlide') return null;
        const { slide, eventType, question, product, value, unic } = dataJSON;
        if (eventType === 'checkbox') {
          const answers =
            typeof value !== 'string'
              ? value.filter((variant) => variant.checked).join('; ')
              : value;
          const variants =
            typeof value !== 'string'
              ? value.map((variant) => variant.title).join('; ')
              : 'нет данных';
          return {
            call_id,
            presentation,
            enterTime,
            slide,
            answers,
            question,
            variants,
            product,
            type: eventType,
            unic: unic || false
          };
        } else
          return {
            call_id,
            presentation,
            enterTime,
            slide,
            value,
            question,
            product,
            type: dataJSON.eventType,
            unic: unic || false
          };
      })
      .filter((el) => el);

  const sendRequestDownLoadStatistic = async () => {
    setError(null);
    setLoading(true);
    const [
      { data: calls },
      { data: userMetrics },
      { data: presMetrics },
      { data: userData },
      { data: presData },
      { data: productsData },
      { data: userGroupData },
      { data: userGroupLinkData },
      { data: PresentationProductLinkData }
    ] = await getAllData(SERVER_URL, axios, token);

    const userDataForEachFunc = (userList) => (userInfo) =>
      (userList[userInfo.id] = {
        name: userInfo.firstName + ' ' + userInfo.lastName,
        email: userInfo.email
      });

    const [presList, userList, userCalls, presCalls, userGroups, presProducts] = fillDictionarys(
      userMetrics,
      presMetrics,
      userData,
      presData,
      userGroupLinkData,
      PresentationProductLinkData,
      productsData,
      userGroupData,
      userDataForEachFunc
    );

    const timeForFilter = {
      from: new Date(dates.from).getTime(),
      to: new Date(dates.to).getTime()
    };

    const callsInfo = calls
      .map((callRecord) => {
        const call = callRecord.data;
        //return empty object for broken visit
        if (call === null) return null;
        if (call.isTest && !testMode) return null;

        const call_id = callRecord.id;
        //fillter for curr dates
        if (timeForFilter.from > call.enterTime || call.enterTime > timeForFilter.to) return null;
        const duration = getDuration(call.enterTime, call.exitTime);
        const durationMinutes = secToTime(duration);
        const userId = userCalls[call_id];
        if (!userList[userId]) {
          console.warn(`Не смог связать пользователя (${userId}) и визит (${call_id}) :(`);
          return;
        }
        const HCPId = call.doctorID;
        //rewrite events with double parse
        call.events = call.events.map(
          (event) => (event.dataJSON = JSON.parse(event.dataJSON)) && event
        );
        const slideList = getSlideList(call.events, call.exitTime);
        const viewedProducts = getViewedProducts(call.events);
        const events = getEvents(call.events, call_id, presList[presCalls[call_id]]);
        return {
          call_id,
          user: userList[userId].name,
          userEmail: userList[userId].email,
          HCPId,
          groups: userGroups[userId],
          products: presProducts[presCalls[call_id]],
          presentation: presList[presCalls[call_id]],
          duration,
          durationMinutes,
          viewedProducts,
          entertime: new Date(call.enterTime),
          slideList,
          events
        };
      })
      .filter((el) => el)
      .filter(getUniq('entertime', 'call_id'))
      .filter((el) =>
        filterByIncludeHour({
          from: timeHHMMtoHourNumber(includeTime.from),
          to: timeHHMMtoHourNumber(includeTime.to),
          currentHour: timeStampToHour(el.entertime)
        })
      )
      .filter((el) => filterByDuration(sessionDurationFilter, el.duration));

    if (callsInfo.length === 0) {
      setLoading(false);
      return setError('No data for current period');
    }

    writeToXLSX(
      getPresentationListView(callsInfo, userGroups, presProducts),
      getSlidesListView(callsInfo),
      getAnswersListView(callsInfo)
    );
  };

  const StatisticFormProps = {
    handleSetSessionDurationFilter,
    handleSetIncludeTime,
    handleChangeTestMode,
    handleChangeDates,
    btnHandler: sendRequestDownLoadStatistic,
    dates,
    sessionDurationFilter,
    includeTime,
    isLoading,
    error,
    testMode,
    btnText
  };

  return (
    <main className={classes.main}>
      <h2>Select date</h2>
      <StatisticForm {...StatisticFormProps} />
    </main>
  );
};

function getUniq(primaryProp, excludeProps = '') {
  const lacmus = new Map();
  const equal = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
  return function ({ [primaryProp]: uniq, [excludeProps]: _, ...props }) {
    const sUniq = uniq.toString();
    if (!lacmus.has(sUniq)) {
      lacmus.set(sUniq, props);
      return true;
    }
    if (lacmus.has(sUniq)) {
      const same = lacmus.get(sUniq);
      return !equal(same, props);
    }
  };
}

function filterByIncludeHour({ from, to, currentHour }) {
  return currentHour >= from && currentHour < to;
}

function filterByDuration(thresholdDuration = 120, currDuration) {
  return Number(currDuration) >= thresholdDuration;
}

// function not(value) {
//   return !value;
// }
/**
 * '08:00' -> 8
 * '09:30' -> 9
 */
function timeHHMMtoHourNumber(time) {
  return Number(time.split(':')[0]);
}

// function extractHourFromDate(date) {
//   return date.getHours();
// }
function timeStampToHour(timeStamp) {
  const date = new Date(timeStamp);
  return date.getHours();
}

const mapStateToProps = ({ user: { token } }) => ({
  token
});

export default connect(mapStateToProps, null)(Statistic);
