import LinearProgress from '@material-ui/core/LinearProgress';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React from 'react';
import { SERVER_URL } from '../config';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'absolute',
    bottom: '0px'
  },
  typography: {
    margin: theme.spacing(2),
    position: 'absolute',
    bottom: '0px',
    left: theme.spacing(0),
    fontSize: '10px'
  }
}));

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    width: '100%',
    backgroundColor: lighten('#ff6c5c', 0.5)
  },
  bar: {
    position: 'absolute',
    width: '100%',
    borderRadius: 20,
    backgroundColor: '#ff6c5c'
  }
})(LinearProgress);

function DiskSpaceInfo() {
  const classes = useStyles();
  const [discSpace, setDiscSpace] = React.useState(null);

  /**
   * {
   *   "diskPath": "C:",
   *   "free": 48473001984,
   *   "size": 238933598208,
   *   "lastDate": 1567522321329
   * }
   */

  function requestDiscSpace() {
    axios.get(SERVER_URL + '/space').then(({ data }) => {
      setDiscSpace(data);
    });
  }

  React.useEffect(() => {
    requestDiscSpace();
  }, []);

  return discSpace ? (
    <>
      <BorderLinearProgress
        className={classes.progress}
        variant='determinate'
        color='secondary'
        value={100 - (discSpace.free * 100) / discSpace.size}
      />
      <Typography component={'span'} className={classes.typography}>
        disc usage: <br />
        free: {(discSpace.free / 1000 / 1000).toFixed()}mb of{' '}
        {(discSpace.size / 1000 / 1000).toFixed()}mb
      </Typography>
    </>
  ) : null;
}

export default DiskSpaceInfo;
