import React from 'react';
import { version } from '../../package.json';
import axios from 'axios';
import { SERVER_URL } from '../config';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  version: {
    margin: theme.spacing(0),
    fontSize: '10px'
  },
  typography: {
    margin: theme.spacing(2),
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(0),
    fontSize: '10px'
  }
}));

const Version = () => {
  const classes = useStyles();
  const [backVersion, setBackVersion] = React.useState(null);
  React.useEffect(() => {
    function requestBackVersion() {
      return axios.get(SERVER_URL + '/api/version').then(({ data }) => {
        setBackVersion(data.version);
      });
    }
    requestBackVersion();
  }, []);
  return (
    <Typography component={'span'} className={classes.typography}>
      back version: {backVersion} <br /> front version: {version}
    </Typography>
  );
};
export default Version;
