module.exports = (local = 'RU') => phrase => {
  const phrases = {
    home: {
      RU: 'Главная',
      EN: 'Home'
    },
    APIKeys: {
      RU: 'API ключи',
      EN: 'API keys'
    },
    managePresentations: {
      RU: 'Управление презентациями',
      EN: 'Manage Presentations'
    },
    manageUsers: {
      RU: 'Управление пользователями',
      EN: 'Manage users'
    },
    createAndManageUserGroups: {
      RU: 'Управление и создание групп пользователей',
      EN: 'Create & manage user groups'
    },
    manageUserGroup: {
      RU: 'Управление группой пользователей',
      EN: 'Manage user group'
    },
    userManagementInAGroup: {
      RU: 'Управление пользователями в группе',
      EN: 'User management in a group'
    },
    managePresentationInGroup: {
      RU: 'Управление презентациями в группе',
      EN: 'Management presentation in a group'
    },
    manageProducts: {
      RU: 'Управление продуктами',
      EN: 'Manage products'
    }
  };
  if (phrases.hasOwnProperty(phrase) && phrases[phrase] && phrases[phrase][local]) {
    return phrases[phrase][local];
  }
};
