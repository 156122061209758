import React, { useState, useEffect } from 'react';
import * as axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Multiselect from './UI/Multiselect/Multiselect';
import { connect } from 'react-redux';
import { SERVER_URL } from '../config';
import useStatisticForm from '../hooks/useStatisticForm';
import * as XLSX from 'xlsx';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  main: {
    marginLeft: theme.spacing(3)
  },
  date: {
    marginRight: theme.spacing(1),
    height: 48,
    flexDirection: 'inherit'
  },
  activeBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    margin: 10,
    maxHeight: 38
  }
}));

const activityStatisticsGetFiltered = (token, { userIds, fromTime, toTime }) => {
  userIds = userIds.length === 0 ? null : userIds;
  return axios
    .post(
      SERVER_URL + '/api/activity-statistics-filter/',
      {
        userIds,
        fromTime,
        toTime
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
    .then((response) => response.data)
    .catch((err) => console.log(err));
};

const convertTime = (dates, times) => {
  const fromTime = new Date(`${times.from} ${dates.from}`).toJSON();
  const toTime = new Date(`${times.to} ${dates.to}`).toJSON();
  return { fromTime, toTime };
};

const preparingJson = (data) => {
  // eslint-disable-next-line
  Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
  };
  return data
    .filter((el) => !el.User.isAdmin && el.User.email !== 'kek@onpoint.ru')
    .map((elem) => {
      const deltaTimeZone = Number(elem.timeZone) + Number(new Date().getTimezoneOffset() / 60);
      const date = new Date(Number(elem.date)).addHours(deltaTimeZone);
      const years = date.getFullYear().toString().slice(-2);
      const months = ('0' + (date.getMonth() + 1)).slice(-2);
      const days = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return {
        User: `${elem.User.firstName} ${elem.User.lastName}`,
        'E-mail': elem.User.email,
        Action: elem.action,
        Time: `${hours}:${minutes}:${seconds}`,
        Date: `${days}.${months}.${years}`
        /*'часовой пояс пользователя': elem.timeZone,
            'часовой пояс скачавшего': -new Date().getTimezoneOffset()/60*/
      };
    })
    .sort((a, b) => {
      if (a.Date > b.Date) return 1;
      if (a.Date < b.Date) return -1;
      if (a.Time > b.Time) return 1;
      if (a.Time < b.Time) return -1;
      return 0;
    });
};

const createSheet = (jsonData) => {
  const preparedData = preparingJson(jsonData);
  const wb = XLSX.utils.book_new();
  const data = XLSX.utils.json_to_sheet(preparedData);
  wb.SheetNames.push('ActivityStatistics');
  wb.Sheets['ActivityStatistics'] = data;
  XLSX.writeFile(wb, `ActivityStatistics.xlsx`);
};

const ActivityStatistics = (props) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [userIds, setUsers] = useState([]);
  const [selectedOptions, setSelectedOption] = useState(null);
  const [
    { dates, handleChangeDates },
    _, // eslint-disable-line
    { includeTime, handleSetIncludeTime },
    { isLoading, setLoading },
    { error, setError }
  ] = useStatisticForm();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(SERVER_URL + '/api/user', {
        headers: { Authorization: `Bearer ${props.token}` }
      });
      const arrOptions = result.data.map((elem) => {
        return { value: elem.id, label: `${elem.lastName} ${elem.firstName}` };
      });
      arrOptions.sort((a, b) => a.label.localeCompare(b.label));
      setOptions(arrOptions);
    };
    fetchData();
  }, [props.token]);

  useEffect(() => {
    if (selectedOptions) {
      const selectedUserIds = selectedOptions.map((elem) => elem.value);
      setUsers(selectedUserIds);
    }
  }, [selectedOptions]);

  const activityStatisticsGet = () => {
    const { fromTime, toTime } = convertTime(dates, includeTime);
    setLoading(true);
    return activityStatisticsGetFiltered(props.token, { userIds, fromTime, toTime }).then(
      (data) => {
        setLoading(false);
        if (data.message) {
          return setError('Не удалось выполнить запрос');
        }
        if (data.length === 0) {
          return setError('Нет данных за указанный период');
        }
        setError('');
        return createSheet(data);
      }
    );
  };

  return (
    <main className={classes.main}>
      <h2>Статистика активности пользователей</h2>
      <div>
        <TextField
          id='datefrom'
          label='From'
          type='date'
          onChange={handleChangeDates('from')}
          defaultValue={dates.from}
          InputLabelProps={{ shrink: true }}
          className={classes.date}
        />

        <TextField
          id='dateto'
          label='To'
          type='date'
          onChange={handleChangeDates('to')}
          defaultValue={dates.to}
          InputLabelProps={{ shrink: true }}
          className={classes.date}
        />
        <TextField
          height='50%'
          id='includeFromTime'
          label='From time'
          type='time'
          defaultValue={includeTime.from}
          onChange={handleSetIncludeTime('from')}
          className={classes.date}
          inputProps={{
            step: 3600 // in seconds
          }}
        />
        <TextField
          id='includeToTime'
          label='To time'
          type='time'
          defaultValue={includeTime.to}
          onChange={handleSetIncludeTime('to')}
          className={classes.date}
          inputProps={{
            step: 3600 // in seconds
          }}
        />
      </div>
      <div className={classes.activeBlock}>
        <Multiselect
          setSelectedOption={setSelectedOption}
          options={options}
          selectedOptions={selectedOptions}
        />
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={activityStatisticsGet}
        >
          Скачать
        </Button>
      </div>
      {isLoading ? <CircularProgress /> : <p />}
      <p>{error}</p>
    </main>
  );
};

const mapStateToProps = ({ user }) => ({
  token: user.token
});

export default connect(mapStateToProps)(ActivityStatistics);

/*
Что трекается:
  1.логин логаут
  2.рендер managePresentations
  3.закрытие страницы
  5.нажатие Начать визит
*/
