import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { increment, incrementAsync, decrement, decrementAsync } from '../reducers/counter';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ROUTES from '../routes';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  header: {
    marginLeft: theme.spacing(3)
  },
  btnGroup: {
    marginLeft: theme.spacing(3),
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  btn: {
    margin: theme.spacing(1)
  }
}));

const filterRoutesItemByAccess = (user, ROUTES) =>
  Object.keys(ROUTES).map((key) => {
    const isRouteForAdmin = ROUTES[key].access.indexOf('admin') !== -1;
    const userIsAdmin = user.isAdmin;
    if (!isRouteForAdmin) {
      return ROUTES[key];
    }
    if (isRouteForAdmin && userIsAdmin) {
      return ROUTES[key];
    }
    return null;
  });

const Home = (props) => {
  const classes = useStyles();
  return (
    <div>
      <h1 className={classes.header}>Home</h1>
      <ButtonGroup className={classes.btnGroup}>
        {filterRoutesItemByAccess(props.user, ROUTES)
          .filter((item) => item)
          .map(({ title, link, icon, hide }, index) => {
            return hide && hide.leftSideMenu ? null : (
              <Link key={title} to={link}>
                <Button variant='contained' color='default' className={classes.btn}>
                  {title}
                </Button>
              </Link>
            );
          })}
      </ButtonGroup>
    </div>
  );
};

const mapStateToProps = ({ counter, user }) => ({
  count: counter.count,
  isIncrementing: counter.isIncrementing,
  isDecrementing: counter.isDecrementing,
  user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      increment,
      incrementAsync,
      decrement,
      decrementAsync
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
