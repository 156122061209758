import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  date: {
    marginRight: theme.spacing(1),
    height: 48,
    flexDirection: 'inherit'
  }
}));

const StatisticForm = ({
  handleSetEnabledVisitPlan,
  handleSetSessionDurationFilter,
  handleSetVisitsPlan,
  handleSetIncludeTime,
  handleChangeTestMode,
  handleChangeDates,
  btnHandler,
  dates,
  sessionDurationFilter,
  includeTime,
  isLoading,
  error,
  testMode,
  enabledVisitPlan,
  visitsPlan,
  btnText
}) => {
  const classes = useStyles();
  return (
    <div className='option-wrap'>
      <TextField
        id='datefrom'
        label='From'
        type='date'
        defaultValue={dates.from}
        onChange={handleChangeDates('from')}
        InputLabelProps={{ shrink: true }}
        className={classes.date}
      />

      <TextField
        id='dateto'
        label='To'
        type='date'
        onChange={handleChangeDates('to')}
        defaultValue={dates.to}
        InputLabelProps={{ shrink: true }}
        className={classes.date}
      />
      <TextField
        id='sessionDuration'
        label='Duration'
        type='number'
        defaultValue={sessionDurationFilter}
        onChange={handleSetSessionDurationFilter}
        className={classes.date}
      />
      <TextField
        height='50%'
        id='includeFromTime'
        label='From time'
        type='time'
        defaultValue={includeTime.from}
        onChange={handleSetIncludeTime('from')}
        className={classes.date}
        inputProps={{
          step: 3600 // in seconds
        }}
      />
      <TextField
        id='includeToTime'
        label='To time'
        type='time'
        defaultValue={includeTime.to}
        onChange={handleSetIncludeTime('to')}
        className={classes.date}
        inputProps={{
          step: 3600 // in seconds
        }}
      />
      {visitsPlan && (
        <FormControlLabel
          control={
            <Checkbox
              checked={enabledVisitPlan}
              onChange={handleSetEnabledVisitPlan}
              value='VisitPlan'
              color='secondary'
            />
          }
          label='План визитов'
        />
      )}
      {enabledVisitPlan && (
        <TextField
          id='visitsPlan'
          label='Visits plan'
          type='number'
          defaultValue={visitsPlan}
          onChange={handleSetVisitsPlan}
          className={classes.date}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={testMode}
            onChange={handleChangeTestMode}
            value='testMode'
            color='primary'
          />
        }
        label='withTest'
      />
      <Button variant='contained' onClick={btnHandler}>
        {btnText}
      </Button>
      {isLoading ? <CircularProgress /> : <p />}
      <p>{error}</p>
    </div>
  );
};

export default StatisticForm;
