import axios from 'axios';

interface UserAPI {
  SERVER_URL: string;
  TOKEN: string;
}
interface createUser {
  firstName: string;
  secondName?: string;
  lastName: string;
  email: string;
  password: string;
}

class UserAPI {
  constructor({ SERVER_URL, TOKEN }: UserAPI) {
    this.SERVER_URL = SERVER_URL;
    this.TOKEN = TOKEN;
    this.createUser = this.createUser.bind(this);
  }

  createUser({
    firstName = '',
    secondName = '',
    lastName = '',
    email = '',
    password = ''
  }: createUser) {
    return axios.post(
      this.SERVER_URL + '/api/user',
      { firstName, secondName, lastName, email, password },
      { headers: { Authorization: `Bearer ${this.TOKEN}` } }
    );
  }
}

export default UserAPI;
