import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function ItemsListFilter({
  title,
  items,
  numberOfChecked,
  classes,
  checked,
  handleToggle,
  handleToggleAll
}) {
  // function sortItemsByName(item1, item2) {
  //   if (item1.name > item2.name) return 1;
  //   if (item1.name < item2.name) return -1;
  //   return 0;
  // }
  const [filterValue, setFilterValue] = React.useState('');
  const handleChange = (e) => {
    setFilterValue(e.target.value);
  };
  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <TextField
        label='Filter'
        name='filter'
        className={classes.filter}
        variant='outlined'
        autoComplete='off'
        value={filterValue}
        onChange={handleChange}
      />
      <List className={classes.list} dense component='div' role='list'>
        {items.map((product) => {
          const labelId = `transfer-list-all-item-${product.id}-label`;
          return (
            <ListItem key={product.id} role='listitem' button onClick={handleToggle(product)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.map(({ id }) => id).indexOf(product.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={
                  <span>
                    <p>{product.name}</p>
                  </span>
                }
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
}
