import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../config';
import ItemsListFilter from './CustomLists/ItemsListFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 400
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  header: {
    padding: theme.spacing(2)
  },
  image: {
    marginRight: theme.spacing(1)
  },
  filter: {
    width: '100%'
  }
}));

const ManagePresentationProduct = ({ user, presentationId }) => {
  const classes = useStyles();
  const [allProducts, setAllProducts] = useState([]);
  const [checked, setChecked] = useState([]);
  const [presProducts, setPresProducts] = useState([]);
  const [hasChangedProduct, setIsChangedProduct] = useState(false);

  const requestAllProducts = async () => {
    const { data } = await axios.get(SERVER_URL + '/api/products', {
      headers: { Authorization: `Bearer ${user.token}` }
    });

    return data.map((product) => ({ id: product.id, name: product.name }));
  };

  const requestPresProducts = async () => {
    const { data } = await axios.get(
      SERVER_URL + `/api/products-by-presentation/${presentationId}`,
      {
        headers: { Authorization: `Bearer ${user.token}` }
      }
    );
    return data.map((product) => ({ id: product.id, name: product.name }));
  };

  useEffect(() => {
    async function ManagePresentationProduct() {
      const [resPres, resAll] = await Promise.all([requestPresProducts(), requestAllProducts()]);
      setPresProducts(resPres);
      setAllProducts(not(resAll, resPres));
    }
    ManagePresentationProduct();
  }, []); // eslint-disable-line

  function not(a, b) {
    return a.filter(({ id }) => b.map(({ id }) => id).indexOf(id) === -1);
  }

  function intersection(a, b) {
    return a.filter(({ id }) => b.map(({ id }) => id).indexOf(id) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };
  const handleToggle = (user) => () => {
    const currentIndex = checked.map(({ id }) => id).indexOf(user.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setPresProducts(presProducts.concat(leftChecked));
    setAllProducts(not(allProducts, leftChecked));
    setChecked(not(checked, leftChecked));
    setIsChangedProduct(true);
  };

  const handleCheckedLeft = () => {
    setAllProducts(allProducts.concat(rightChecked));
    setPresProducts(not(presProducts, rightChecked));
    setChecked(not(checked, rightChecked));
    setIsChangedProduct(true);
  };

  const handleSavePresentationInProduct = (presProducts) => () => {
    return axios
      .post(
        SERVER_URL + '/api/presentation-product/' + presentationId,
        {
          productIDs: presProducts.map((product) => product.id)
        },
        {
          headers: { Authorization: `Bearer ${user.token}` }
        }
      )
      .then(setIsChangedProduct(false))
      .catch(console.log);
  };

  const leftChecked = intersection(checked, allProducts);
  const rightChecked = intersection(checked, presProducts);

  const customListProps = {
    numberOfChecked: numberOfChecked,
    classes: classes,
    handleToggleAll: handleToggleAll,
    handleToggle: handleToggle,
    checked: checked
  };

  return (
    <>
      <Grid container spacing={2} justify='center' alignItems='flex-start' className={classes.root}>
        <Grid item>
          <ItemsListFilter title='Все продукты' items={allProducts} {...customListProps} />
        </Grid>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Button
              variant='outlined'
              size='small'
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label='move selected presProducts'
            >
              <ArrowForwardIcon />
            </Button>
            <Button
              variant='outlined'
              size='small'
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label='move selected allProducts'
            >
              <ArrowBackIcon />
            </Button>
            <Button
              disabled={!hasChangedProduct}
              variant='outlined'
              size='small'
              className={classes.button}
              onClick={handleSavePresentationInProduct(presProducts)}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <ItemsListFilter
            title='Подключенные продукты'
            items={presProducts}
            {...customListProps}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ManagePresentationProduct;
