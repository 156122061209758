import { Button, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Collections from '@material-ui/icons/Collections';
import Group from '@material-ui/icons/Group';
import GroupAdd from '@material-ui/icons/GroupAdd';
import LinkIcon from '@material-ui/icons/Link';
import Person from '@material-ui/icons/Person';
import * as axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../config';
import ROUTES from '../routes';
const phrases = require('../text-constants')();

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  btn: {
    margin: theme.spacing(0, 1)
  },
  icon: {
    margin: theme.spacing(0, 0.5)
  },
  link: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  chip: {
    margin: theme.spacing(1)
  }
}));

function AddUserGroups(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    groups: null,
    error: null,
    name: ''
  });
  useEffect(() => {
    function initGetUserGroups() {
      getUserGroups();
    }
    initGetUserGroups();
  }, []); // eslint-disable-line
  function getUserGroups() {
    return axios
      .get(SERVER_URL + '/api/user-group', { headers: { Authorization: `Bearer ${props.token}` } })
      .then((result) => {
        return result;
      })
      .then((result) => setState({ ...state, groups: result.data }))
      .catch(({ message }) => {
        setState({ ...state, error: message });
      });
  }

  const isUniqNameForGroup = (groups, value) =>
    groups.map(({ name }) => name).indexOf(value) === -1;

  const isNotEmptyString = (value) => !value.trim();

  function createUserGroup(ev) {
    ev.nativeEvent.preventDefault();
    return axios
      .post(
        SERVER_URL + '/api/user-group',
        { name: state.name },
        { headers: { Authorization: `Bearer ${props.token}` } }
      )
      .then((result) => {
        return result;
      })
      .then(getUserGroups)
      .catch(({ message }) => {
        setState({ ...state, error: message });
      });
  }

  const handleDeleteGroup = (groupID) => () => {
    if (window.confirm('Ты уверен что хочешь это сделать?')) {
      return axios
        .delete(SERVER_URL + '/api/user-group/' + groupID, {
          headers: { Authorization: `Bearer ${props.token}` }
        })
        .then(getUserGroups);
    }
  };

  function handleChangeInput({ target: { value } }) {
    setState({ ...state, name: value });
  }

  function not(bool) {
    return !bool;
  }
  return (
    <>
      {state.groups === null ? (
        state.error === null ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Typography color='secondary'>{state.error}</Typography>
        )
      ) : (
        state.groups.map(({ name, id }) => (
          <Paper className={classes.paper}>
            <List key={name} className={classes.root}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Group />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary='Jan 9, 2014' />
              </ListItem>
              <div>
                <Link className={classes.link} to={`${ROUTES.manageUserGroup.link}/${id}`}>
                  <Button color='default' variant='contained'>
                    <Person className={classes.icon} />
                    <LinkIcon className={classes.icon} />
                    <Group className={classes.icon} />
                    {phrases('userManagementInAGroup')}
                  </Button>
                </Link>
                <Link className={classes.link} to={`${ROUTES.manageUserGroupPres.link}/${id}`}>
                  <Button color='default' variant='contained'>
                    <Collections className={classes.icon} />
                    <LinkIcon className={classes.icon} />
                    <Group className={classes.icon} />
                    {phrases('managePresentationInGroup')}
                  </Button>
                </Link>
                <Button
                  onDoubleClick={handleDeleteGroup(id)}
                  className={classes.btn}
                  variant='contained'
                  color='secondary'
                >
                  DELETE
                </Button>
              </div>
            </List>
          </Paper>
        ))
      )}
      <Paper className={classes.paper}>
        <form onSubmit={createUserGroup}>
          <TextField onChange={handleChangeInput} name='name' value={state.name} />
          <Button
            color='primary'
            variant='contained'
            className={classes.btn}
            disabled={
              (state.groups && not(isUniqNameForGroup(state.groups, state.name))) ||
              isNotEmptyString(state.name)
            }
            onClick={createUserGroup}
          >
            <GroupAdd className={classes.icon} />
            Create
          </Button>
        </form>
      </Paper>
    </>
  );
}

const mapStateToProps = ({ user }) => ({
  token: user.token
});

export default connect(mapStateToProps)(AddUserGroups);
