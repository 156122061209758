import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import { Link } from 'react-router-dom';
import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { SERVER_URL } from '../config';
import { history } from '../store';
import routes from '../routes';
import ManagePresentationProduct from './ManagePresentationProduct';
import ManagePresentationUserGroup from './ManagePresentationUserGroup';
import Grid from '@material-ui/core/Grid';

const presentationApiUrl = '/api/presentation';

const inintState = {
  name: '',
  isApproved: false,
  isDisable: false
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  icon: {
    margin: theme.spacing(0.5)
  },
  btn: {
    margin: theme.spacing(2)
  },
  archivesList: {
    paddingLeft: theme.spacing(1.9)
  },
  archive: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    width: 'fit-content',
    justifyContent: 'space-between',
    margin: '8px 0'
  },
  archiveItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const EditPresentation = ({
  user,
  match: {
    params: { presentationId }
  }
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState(inintState);
  const [stateFromServer, setStateFromServer] = React.useState(inintState);
  const [files, setFilesList] = React.useState([]);
  const [hasEqualStates, setHasEqualStates] = React.useState(false);

  React.useEffect(() => {
    const requestPresentation = () => {
      axios
        .get(SERVER_URL + presentationApiUrl + '/' + presentationId, {
          headers: { Authorization: `Bearer ${user.token}` }
        })
        .then(({ data: presentation }) => {
          setState(presentation);
          setStateFromServer(presentation);
          setHasEqualStates(true);
          requestOtherFiles(presentationId);
        });
    };
    requestPresentation();
  }, []); // eslint-disable-line

  function requestOtherFiles(presentationId) {
    return axios
      .get(SERVER_URL + '/api/request-other-presentation-files/' + presentationId, {
        headers: { Authorization: `Bearer ${user.token}` }
      })
      .then(({ data }) => setFilesList(data));
  }

  const handleChangeText = (name) => (event) => {
    const newState = { ...state, [name]: event.target.value };
    setState(newState);
    setHasEqualStates(statesIsEqual(newState, stateFromServer));
  };

  const handleChangeCheckbox = (name) => (event) => {
    const newState = { ...state, [name]: event.target.checked };
    setState(newState);
    setHasEqualStates(statesIsEqual(newState, stateFromServer));
  };

  function statesIsEqual(state1, state2) {
    return JSON.stringify(state1) === JSON.stringify(state2);
  }

  const handleDeletePresentation = (presentationId) => () => {
    if (window.confirm('Ты уверен что хочешь это сделать?')) {
      return axios
        .delete(SERVER_URL + '/api/delete-presentation/' + presentationId, {
          headers: { Authorization: `Bearer ${user.token}` }
        })
        .then(({ data }) => history.push(routes.presentations.link));
    }
  };

  const handleDeletePresentationArchive = (presentationArchiveName) => () => {
    if (window.confirm('Ты уверен что хочешь это сделать?')) {
      axios.delete();
      return axios
        .delete(SERVER_URL + '/api/delete-presentation-archive/' + presentationId, {
          headers: { Authorization: `Bearer ${user.token}` },
          data: { removedArchive: presentationArchiveName }
        })
        .then(({ data }) => requestOtherFiles(presentationId));
    }
  };

  const handleSavePresentation = (id) => (event) => {
    const { name, isApproved, isDisable } = state;

    const updateProps = {
      name,
      isApproved,
      isDisable
    };

    axios
      .put(
        SERVER_URL + presentationApiUrl,
        { target: { id: presentationId }, props: updateProps },
        {
          headers: { Authorization: `Bearer ${user.token}` }
        }
      )
      .then(({ data }) => {
        setState(data);
        setStateFromServer(data);
        setHasEqualStates(true);
      });
  };

  return (
    <>
      <form className={classes.container}>
        <Link to={routes.presentations.link}>
          <Button>
            <Avatar>
              <IconArrowBack />
            </Avatar>
          </Button>
        </Link>
        <TextField
          id='standard-name'
          label='Name'
          className={classes.textField}
          value={state.name}
          onChange={handleChangeText('name')}
          margin='normal'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.isApproved}
              onChange={handleChangeCheckbox('isApproved')}
              value='isApproved'
              color='primary'
            />
          }
          label='isApproved'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={state.isDisable}
              onChange={handleChangeCheckbox('isDisable')}
              value='isDisable'
            />
          }
          label='isDisable'
        />
        <Button
          disabled={hasEqualStates}
          variant='contained'
          className={classes.btn}
          onClick={handleSavePresentation(presentationId)}
        >
          SAVE <SaveIcon className={classes.icon} />
        </Button>
        <Button
          onDoubleClick={handleDeletePresentation(presentationId)}
          className={classes.btn}
          variant='contained'
          color='secondary'
        >
          DELETE
        </Button>
      </form>
      <ul className={classes.archivesList}>
        {[...files]
          .reverse()
          .filter((file) => file.indexOf('.zip') !== -1)
          .map((file) => {
            const activeArchiveName = stateFromServer.filePath.split('/')[2];
            return (
              <li key={file} className={classes.archive}>
                <span className={classes.archiveItem}>archive name: {file};</span>
                <span className={classes.archiveItem}>
                  upload date: {parseData(file.split('_')[0])}
                </span>
                {activeArchiveName === file ? (
                  <Button>Active</Button>
                ) : (
                  <Button
                    onClick={handleDeletePresentationArchive(file)}
                    color='secondary'
                    className={classes.deleteArchiveBtn}
                  >
                    DELETE ARCHIVE
                  </Button>
                )}
              </li>
            );
          })}
      </ul>
      <Grid container spacing={2} justify='flex-start' alignItems='flex-start'>
        <Grid item>
          <ManagePresentationProduct user={user} presentationId={presentationId} />
        </Grid>
        <Grid item>
          <ManagePresentationUserGroup user={user} presentationId={presentationId} />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps)(EditPresentation);

function parseData(timestamp) {
  const date = new Date();
  date.setTime(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + seconds;
}
