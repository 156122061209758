import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import IconArchive from '@material-ui/icons/Archive';
import CloseIcon from '@material-ui/icons/Close';
import CollectionsIcon from '@material-ui/icons/Collections';
import IconEdit from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import * as axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../config';
import { activityStatisticsWrite } from './ActivityStatisticsUtils';
import PresentationPreview from './PresentationPreview';
window.moment = moment;

/* треки сворачиваний ращворачиваний */
/*
const onVisibilityChange = () => {
  //console.log(user.isMCP) //undefined
  return document.hidden
  ? activityStatisticsWrite('Свернул страницу')
  : activityStatisticsWrite('Развернул страницу')
}
document.addEventListener("visibilitychange", onVisibilityChange)
*/

//activityStatisticsWrite('Открыл Страницу');
window.onbeforeunload = closingCode;
function closingCode() {
  activityStatisticsWrite('Закрыл Презентации');
  return null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  textField: {
    margin: theme.spacing(2)
  },
  dialogButton: {
    margin: theme.spacing(2)
  },
  errMessage: {
    textAlign: 'center',
    margin: theme.spacing(1)
  },
  errCreate: {
    textAlign: 'center',
    margin: theme.spacing(4)
  },
  icon: {
    margin: theme.spacing(0.5)
  },
  listItem: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  image: {
    margin: theme.spacing(2)
  },
  form: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    border: 'solid 1px rgba(0,0,0,.3)',
    borderRadius: '5px',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
  btn: {
    margin: theme.spacing(2)
  },
  progress: {
    margin: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  filter: {
    marginLeft: theme.spacing(1.5)
  },
  docBthWrap: {
    margin: 'auto',
    marginBottom: theme.spacing(2),
    maxWidth: '80%'
  },
  docHref: {
    textDecoration: 'none',
    margin: 'auto',
    marginBottom: theme.spacing(2)
  },
  docScroll: {
    margin: theme.spacing(3),
    height: 140,
    width: 380,
    overflow: 'auto'
  },
  docErr: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(5),
    height: 140,
    width: 380,
    textAlign: 'center'
  },
  docInactive: {
    textAlign: 'left',
    textTransform: 'none'
  },
  docActive: {
    textTransform: 'none',
    backgroundColor: '#e0e0e0',
    textAlign: 'left'
  },
  docFilter: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  copyArea: {
    opacity: 0,
    width: 0,
    height: 0,
    padding: 0,
    margin: 0,
    border: 'none'
  }
}));

function ManagePresentations(props) {
  const classes = useStyles();
  const inputFile = useRef(null);
  const inputName = useRef(null);
  const [state, setPresentationList] = useState({
    presentations: null,
    error: null,
    uploading: false
  });
  const [dialogIsOpened, setDialogVisible] = useState(false);

  const [filterMask, setFilterMask] = React.useState('');
  function handleSetFilterMask({ target: { value } }) {
    setFilterMask(value);
  }

  useEffect(() => {
    activityStatisticsWrite('Открыл Презентации');
    function getPresentations() {
      return refreshPresentationList();
    }
    getPresentations();
    // eslint-disable-next-line
  }, []);

  /*   
   useEffect(() => {
    activityStatisticsWrite('Открыл Презентации')    
    return () => {//трекает нe закрытие вкладки а только на переход на другую
      activityStatisticsWrite('Закрыл Управление презентациями')
    }
  }, [])
*/
  function refreshPresentationList() {
    return axios
      .get(SERVER_URL + '/api/presentation', {
        headers: { Authorization: `Bearer ${props.token}` }
      })
      .then((result) => {
        return result;
      })
      .then((result) => setPresentationList({ presentations: result.data }))
      .catch(({ message }) => {
        setPresentationList({ ...state, error: message });
      });
  }

  function uploadFileForPresentation(e) {
    e.nativeEvent.preventDefault();
    var formData = new FormData();
    formData.append('file', e.target[1].files[0]);
    formData.append('presID', e.target[0].value);
    setPresentationList({ ...state, uploading: true });
    return axios
      .patch(SERVER_URL + '/api/presentation', formData, {
        headers: { Authorization: `Bearer ${props.token}` }
      })
      .then((result) => {
        setPresentationList({ ...state, uploading: false });
        refreshPresentationList();
        return result;
      });
  }
  const hasPresentationsListLengthGreaterZero =
    state.presentations && state.presentations.length > 0;

  return (
    <>
      <>
        {hasPresentationsListLengthGreaterZero ? (
          <TextField
            placeholder='Filter...'
            name='filter'
            value={filterMask}
            onChange={handleSetFilterMask}
            className={classes.filter}
          />
        ) : null}
      </>
      {state.presentations === null ? (
        state.error === null ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Typography color='secondary'>{state ? state.error : null}</Typography>
        )
      ) : (
        state.presentations
          .filter(
            (presentation) =>
              new RegExp(filterMask).test(presentation.name) && !presentation.isDeleted
          )
          .map(({ name, id, sharedHref, updatedAt, filePath, user }) => {
            const propsForPres = {
              name: name,
              id: id,
              sharedHref: sharedHref,
              updatedAt: updatedAt,
              filePath: filePath,
              classes: classes,
              token: props.token,
              state: state,
              uploadFileForPresentation: uploadFileForPresentation,
              inputName: inputName,
              inputFile: inputFile,
              refreshPresentationList: refreshPresentationList,
              user: props.user
            };
            return <Presentation key={id} {...propsForPres} />;
          })
      )}
      {props.user.isAdmin ? (
        <>
          <Fab className={classes.fab} onClick={() => setDialogVisible(true)} color='primary'>
            <AddIcon />
          </Fab>
          <PresentationDialog
            token={props.token}
            open={dialogIsOpened}
            refreshPresentationList={refreshPresentationList}
            onClose={() => setDialogVisible(false)}
          />
        </>
      ) : null}
    </>
  );
}

const mapStateToProps = ({ user }) => ({
  token: user.token,
  user
});

export default connect(mapStateToProps)(ManagePresentations);

function Presentation(props) {
  const {
    name,
    id,
    sharedHref,
    updatedAt,
    filePath,
    classes,
    token,
    state,
    uploadFileForPresentation,
    inputName,
    inputFile,
    refreshPresentationList,
    user
  } = props;
  const [creatingShared, setCreatingShared] = useState(false);
  const [dialogIsOpened, setDialogVisible] = useState(false);

  const createSharedHref = (id) => {
    return function () {
      setCreatingShared(true);
      return axios
        .post(
          SERVER_URL + '/api/share-presentation/' + id,
          {},
          {
            headers: { Authorization: `Bearer ${props.token}` }
          }
        )

        .then((result) => {
          setCreatingShared(false);
          refreshPresentationList();
        });
    };
  };
  const hasArchive = filePath !== null;

  return (
    <>
      {user.isAdmin ? (
        <Paper key={id} className={classes.paper}>
          <List key={id} className={classes.root}>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar>
                  <CollectionsIcon />
                </Avatar>
              </ListItemAvatar>
              <PresentationPreview
                key={filePath}
                classes={classes.image}
                id={id}
                serverUrl={SERVER_URL}
              />
              <ListItemText
                key={id}
                primary={name}
                secondary={`updated at: ${moment(updatedAt).format('DD.MM.YYYY HH:mm')}`}
              />
              <ChipsForPres presentationID={id} token={token} />
              <Link to={`/edit-presentation/${id}`}>
                <Button variant='contained' className={classes.btn} title='Edit presentation'>
                  <Avatar>
                    <IconEdit className={classes.icon} />
                  </Avatar>
                </Button>
              </Link>
              {hasArchive && sharedHref === null ? (
                <Button
                  variant='contained'
                  className={classes.btn}
                  onClick={createSharedHref(id)}
                  title='Create external link for presentation'
                  disabled={creatingShared}
                >
                  {creatingShared ? 'preparing link...' : 'get shared'}
                  <ShareIcon className={classes.icon} />
                </Button>
              ) : null}
              {sharedHref !== null ? (
                <a
                  target='_blank' // eslint-disable-line
                  href={SERVER_URL + sharedHref + '/index.html'}
                  title='Open presentation in a new tab'
                >
                  <Button variant='contained' className={classes.btn}>
                    <Avatar>
                      <ShareIcon className={classes.icon} />
                    </Avatar>
                  </Button>
                </a>
              ) : null}

              {hasArchive ? (
                <a href={`${SERVER_URL}/${id}/${filePath}`} title='Download active archive'>
                  <Button variant='contained' className={classes.btn}>
                    <Avatar>
                      <IconArchive className={classes.icon} />
                    </Avatar>
                  </Button>
                </a>
              ) : null}
              {state.uploading ? (
                <CircularProgress className={classes.progress} />
              ) : (
                <form className={classes.form} onSubmit={uploadFileForPresentation}>
                  <input hidden ref={inputName} readOnly type='text' value={id} />
                  <input ref={inputFile} required type='file' name='file' />
                  <button>Загрузить</button>
                </form>
              )}
            </ListItem>
          </List>
        </Paper>
      ) : null}
      {user.isMCP ? (
        <Paper key={id} className={classes.paper}>
          <List key={id} className={classes.root}>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar>
                  <CollectionsIcon />
                </Avatar>
              </ListItemAvatar>
              <PresentationPreview
                key={filePath}
                classes={classes.image}
                id={id}
                serverUrl={SERVER_URL}
              />
              <ListItemText
                key={id}
                primary={name}
                secondary={`updated at: ${moment(updatedAt).format('DD.MM.YYYY HH:mm')}`}
              />
              {hasArchive && sharedHref === null ? (
                <Button
                  variant='contained'
                  className={classes.btn}
                  onClick={createSharedHref(id)}
                  title='Create external link for presentation'
                  disabled={creatingShared}
                >
                  {creatingShared ? 'preparing link...' : 'Подготовить визит'}
                  <ShareIcon className={classes.icon} />
                </Button>
              ) : null}
              {sharedHref !== null ? (
                <Button
                  variant='contained'
                  className={classes.btn}
                  onClick={() => setDialogVisible(true)}
                >
                  Провести визит
                  <ShareIcon className={classes.icon} />
                </Button>
              ) : null}
            </ListItem>
          </List>
          <DoctorDialog
            sharedHref={sharedHref}
            token={props.token}
            open={dialogIsOpened}
            user={user}
            refreshPresentationList={refreshPresentationList}
            onClose={() => setDialogVisible(false)}
          />
        </Paper>
      ) : null}
    </>
  );
}

function PresentationDialog(props) {
  const classes = useStyles();
  const { onClose } = props;
  const [values, setValues] = React.useState({
    name: ''
  });
  const [errMessage, setErrMessage] = React.useState('');

  function handleClose() {
    onClose(props.onClose);
  }

  const handlePresKey = (event) => {
    if (event.key === 'Enter') {
      sendRequestToCreatePresentation();
      event.preventDefault();
    }
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  const sendRequestToCreatePresentation = () => {
    axios
      .post(
        SERVER_URL + '/api/presentation-like-consumer',
        {
          name: values.name
        },
        {
          headers: { Authorization: `Bearer ${props.token}` }
        }
      )
      .then(() => {
        props.refreshPresentationList();
        handleClose();
        setValues({ name: '' });
        window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
      })
      .catch((err) => {
        setErrMessage(err.response.data.errors['0'].message);
      });
  };

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={props.open}>
      <DialogTitle id='simple-dialog-title'>Создать новую презентацию</DialogTitle>
      <TextField
        onKeyPress={handlePresKey}
        id='standard-name'
        label='Name'
        className={classes.textField}
        value={values.name}
        onChange={handleChange('name')}
        margin='normal'
        autoComplete='off'
      />
      <Button
        className={classes.dialogButton}
        onClick={sendRequestToCreatePresentation}
        variant='contained'
        color='primary'
        disabled={values.name === ''}
      >
        Создать
      </Button>
      <Typography className={classes.errMessage} color='secondary'>
        {errMessage}
      </Typography>
    </Dialog>
  );
}

function DoctorDialog(props) {
  const classes = useStyles();
  const { onClose, sharedHref, user } = props;
  const [filterMask, setFilterMask] = React.useState('');
  const [dataDoc, changeDataDoc] = React.useState({
    indexDoc: null,
    idDoc: ''
  });
  const remoteCallAddress = SERVER_URL + '/api/remote-call';
  const [roomLink, setRoomLink] = React.useState('');
  const [docBtnText, setDocBtnText] = React.useState('Получить ссылку');
  const textAreaRef = useRef(null);

  // const hasDoctorsListLengthGreaterZero =
  //   doctors &&
  //   doctors.length > 0 &&
  //   doctors.filter(
  //     ({ phone, email }) => new RegExp(filterMask).test(phone) || new RegExp(filterMask).test(email)
  //   ).length > 0;

  function handleClose() {
    onClose(props.onClose);
    changeDataDoc({ indexDoc: null, idDoc: '' });
    setFilterMask('');
    setDocBtnText('Получить ссылку');
    setRoomLink('');
  }

  function handleSetFilterMask({ target: { value } }) {
    const newValue = value.replace(/\+/g, '\\+').replace(/\s/g, '').replace(/-/g, '');
    setFilterMask(newValue);
    if (!newValue.replace('\\+', '').includes('_'))
      changeDataDoc({ indexDoc: null, idDoc: newValue.replace('\\+', '') });
    else changeDataDoc({ indexDoc: null, idDoc: '' });
  }

  // const doctorClick = (index, id) => {
  //   changeDataDoc({ indexDoc: index, idDoc: id });
  //   setDocBtnText('Получить ссылку');
  // };

  const copyDocUrl = async (e) => {
    const { idDoc } = dataDoc;

    setDocBtnText('Ссылка скопирована');
    if (!roomLink) {
      const { data: roomId } = await axios.post(
        SERVER_URL + '/api/remote-call',
        {
          idDoc,
          idMp: user.id,
          token: props.token
        },
        {
          headers: { Authorization: `Bearer ${props.token}` }
        }
      );

      setRoomLink(`${remoteCallAddress}/${roomId}`);

      await window.navigator.clipboard.writeText(`${remoteCallAddress}/${roomId}`);
    } else {
      await window.navigator.clipboard.writeText(roomLink);
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={props.open}>
      {sharedHref ? (
        <>
          <CloseIcon
            onClick={handleClose}
            style={{ position: 'absolute', top: '0px', right: '0px' }}
          />
          <DialogTitle id='simple-dialog-title'>Введите телефон врача</DialogTitle>
          <>
            <InputMask
              value={filterMask.replace(/\\/g, '')}
              onChange={handleSetFilterMask}
              mask='+7\ 999-999-99-99'
            >
              {() => (
                <TextField placeholder='Filter...' className={classes.docFilter} name='filter' />
              )}
            </InputMask>
          </>
          {dataDoc.idDoc !== '' ? (
            <>
              <div className={classes.docBthWrap}>
                <Button
                  className={classes.docButton}
                  variant='contained'
                  color='primary'
                  disabled={false}
                  onClick={copyDocUrl}
                >
                  {docBtnText}
                </Button>
                <textarea
                  className={classes.copyArea}
                  ref={textAreaRef}
                  onChange={() => {}}
                  value={roomLink}
                />
              </div>
              <a
                className={classes.docHref}
                target='_blank' // eslint-disable-line
                href={SERVER_URL + sharedHref + '/index.html'}
                title='Open presentation in a new tab'
              >
                <Button
                  className={classes.docButton}
                  variant='contained'
                  color='primary'
                  disabled={false}
                  onClick={() => activityStatisticsWrite('Начал визит')}
                >
                  Начать визит
                </Button>
              </a>
            </>
          ) : (
            <>
              <div className={classes.docBthWrap}>
                <Button
                  className={classes.docButton}
                  variant='contained'
                  color='primary'
                  disabled={true}
                >
                  {docBtnText}
                </Button>
              </div>
              <div className={classes.docBthWrap}>
                <Button
                  className={classes.docButton}
                  variant='contained'
                  color='primary'
                  disabled={true}
                >
                  Начать визит
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <Typography className={classes.errCreate} color='secondary'>
          Презентация не найдена
        </Typography>
      )}
    </Dialog>
  );
}

function ChipsForPres({ presentationID, token }) {
  const classes = useStyles();
  const [productsList, setProductsList] = useState([]);
  const [isLoading, setIsLoaded] = useState(true);
  const [isError] = useState(false);
  useEffect(() => {
    function getProductsByPresesntationID() {
      axios
        .get(`${SERVER_URL}/api/products-by-presentation/${presentationID}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((result) => {
          setProductsList(result.data);
          setIsLoaded(false);
        });
    }
    getProductsByPresesntationID();
  }, []); // eslint-disable-line
  return productsList.length > 0 ? (
    productsList.map((product) => (
      <Chip className={classes.chip} size='small' key={product.id} label={product.name} />
    ))
  ) : isLoading ? (
    <CircularProgress className={classes.progress} color={isError ? 'secondary' : 'primary'} />
  ) : (
    'No products'
  );
}
