import { Button, Paper, TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconEdit from '@material-ui/icons/Edit';
import IconPerson from '@material-ui/icons/Person';
import * as axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../config';
import routes from '../routes';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  textField: {
    margin: theme.spacing(1)
  },
  btn: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  newUser: {
    margin: theme.spacing(1)
  },
  userForm: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));

function ManageUsers(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    users: null,
    error: null
  });

  const [inputState, setInputState] = useState({
    firstName: '',
    secondName: '',
    lastName: '',
    email: '',
    password: ''
  });

  function requestUserList() {
    return axios
      .get(SERVER_URL + '/api/user', { headers: { Authorization: `Bearer ${props.token}` } })
      .then((result) => {
        return result;
      })
      .then((result) =>
        result.data !== 'permission denied'
          ? setState({
              users: result.data
            })
          : setState({ ...state, error: result.data })
      )
      .catch(({ message }) => {
        setState({ ...state, error: message });
      });
  }

  useEffect(() => {
    function initRequestUserList() {
      requestUserList();
    }
    initRequestUserList();
  }, []); // eslint-disable-line

  function handleChangeInput(name) {
    return ({ target: { value } }) => setInputState({ ...inputState, [name]: value });
  }

  function createUser(ev) {
    ev.nativeEvent.preventDefault();
    return axios
      .post(
        SERVER_URL + '/api/user',
        { ...inputState },
        { headers: { Authorization: `Bearer ${props.token}` } }
      )
      .then((result) => {
        return result;
      })
      .then(requestUserList)
      .catch(({ message }) => {
        setState({ ...state, error: message });
      });
  }

  const handleDeleteUser = (userId) => () => {
    if (window.confirm('Ты уверен что хочешь это сделать?')) {
      return axios
        .delete(SERVER_URL + '/api/user/' + userId, {
          headers: { Authorization: `Bearer ${props.token}` }
        })
        .then(requestUserList);
    }
  };

  return (
    <>
      {state.users && state.users.length > 5 ? (
        <UserForm
          classes={classes}
          state={inputState}
          handleChangeInput={handleChangeInput}
          createUser={createUser}
        />
      ) : null}
      {state.users === null ? (
        state.error === null ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Typography color='secondary'>{state.error}</Typography>
        )
      ) : (
        <>
          {state.users.map(({ id, email, firstName, secondName, lastName }) => (
            <Paper key={id} className={classes.paper}>
              <List key={id} className={classes.root}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <IconPerson />
                    </Avatar>
                  </ListItemAvatar>
                  <Link to={`${routes.editUser.link}/${id}`}>
                    <Button variant='contained' className={classes.btn} title='Edit user'>
                      <Avatar>
                        <IconEdit className={classes.icon} />
                      </Avatar>
                    </Button>
                  </Link>
                  <Button
                    onDoubleClick={handleDeleteUser(id)}
                    className={classes.btn}
                    variant='contained'
                    color='secondary'
                  >
                    DELETE
                  </Button>
                  <ListItemText
                    primary={email}
                    secondary={`${lastName} ${firstName} ${secondName}`}
                  />
                </ListItem>
              </List>
            </Paper>
          ))}
          <UserForm
            classes={classes}
            state={inputState}
            handleChangeInput={handleChangeInput}
            createUser={createUser}
          />
        </>
      )}
    </>
  );
}

function UserForm({ classes, state, handleChangeInput, createUser }) {
  return (
    <Paper className={classes.newUser}>
      <form onSubmit={createUser} className={classes.userForm}>
        <TextField
          onChange={handleChangeInput('email')}
          name='email'
          label='email'
          className={classes.textField}
          value={state.email}
          margin='normal'
        />
        <TextField
          onChange={handleChangeInput('firstName')}
          name='firstName'
          label='Имя'
          className={classes.textField}
          value={state.firstName}
          margin='normal'
        />
        <TextField
          onChange={handleChangeInput('secondName')}
          name='secondName'
          label='Отчество'
          className={classes.textField}
          value={state.secondName}
          margin='normal'
        />
        <TextField
          onChange={handleChangeInput('lastName')}
          name='lastName'
          label='Фамилия'
          className={classes.textField}
          value={state.lastName}
          margin='normal'
        />
        <TextField
          onChange={handleChangeInput('password')}
          name='password'
          label='Пароль'
          type='password'
          className={classes.textField}
          value={state.password}
          margin='normal'
        />
        <Button
          className={classes.textField}
          color='primary'
          variant='contained'
          onClick={createUser}
          disabled={
            !hasAllFieldsFilled(state.email, state.firstName, state.lastName, state.password)
          }
        >
          Create
        </Button>
      </form>
    </Paper>
  );
}

function hasAllFieldsFilled(...fields) {
  return fields.every((field) => field !== '');
}

const mapStateToProps = ({ user }) => ({
  token: user.token
});

export default connect(mapStateToProps)(ManageUsers);
