import { useState } from 'react';
import { getTodaySplitForMaterialUI } from '../components/StatisticV2/utils';

const useStatisticForm = () => {
  const today = getTodaySplitForMaterialUI();

  const [dates, setDates] = useState({ from: today, to: today });
  const [sessionDurationFilter, setSessionDurationFilter] = useState(120);
  const [includeTime, setIncludeRange] = useState({ from: '08:00', to: '20:00' });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [testMode, setTestMode] = useState(false);
  const [enabledVisitPlan, setEnabledVisitPlan] = useState(false);
  const [visitsPlan, setVisitsPlan] = useState(100);

  const handleChangeTestMode = () => setTestMode(!testMode);
  const handleChangeDates = (date) => ({ target: { value } }) =>
    setDates({ ...dates, [date]: value });

  const handleSetIncludeTime = (propName) => ({ target: { value } }) =>
    value && setIncludeRange({ ...includeTime, [propName]: value });

  const handleSetVisitsPlan = (e) => setVisitsPlan(Number(e.target.value));

  const handleSetEnabledVisitPlan = () => setEnabledVisitPlan(!enabledVisitPlan);

  const handleSetSessionDurationFilter = ({ target: { value } }) =>
    setSessionDurationFilter(+value);

  return [
    { dates, handleChangeDates, setDates },
    { sessionDurationFilter, handleSetSessionDurationFilter, setSessionDurationFilter },
    { includeTime, handleSetIncludeTime, setIncludeRange },
    { isLoading, setLoading },
    { error, setError },
    { testMode, handleChangeTestMode, setTestMode },
    { enabledVisitPlan, handleSetEnabledVisitPlan, setEnabledVisitPlan },
    { visitsPlan, handleSetVisitsPlan, setVisitsPlan }
  ];
};

export default useStatisticForm;
