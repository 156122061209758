import axios from 'axios';

interface UserGroupAPI {
  SERVER_URL: string;
  TOKEN: string;
}
interface setUsersInGroup {
  groupId: number;
  userIds: number[]
}

class UserGroupAPI {
  constructor({ SERVER_URL, TOKEN }: UserGroupAPI) {
    this.SERVER_URL = SERVER_URL;
    this.TOKEN = TOKEN;
    this.setUsersInGroup = this.setUsersInGroup.bind(this)
  }

  setUsersInGroup({
    groupId,
    userIds = []
  }: setUsersInGroup) {
    return axios.post(
      this.SERVER_URL + '/api/user-group/' + groupId,
      { userIds },
      { headers: { Authorization: `Bearer ${this.TOKEN}` } }
    );
  }
}

export default UserGroupAPI;
