import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { handleChange, handleSubmiteInputs } from '../reducers/user';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  errText: {
    color: 'red'
  }
}));

function Authorization(props) {
  const classes = useStyles();
  return (
    <div>
      <div>
        <TextField
          id='standard-name'
          label='Email'
          className={classes.textField}
          value={props.email}
          onChange={props.handleChange('email')}
          margin='normal'
        />
      </div>
      <div>
        <TextField
          id='standard-password-input'
          label='Password'
          value={props.password}
          className={classes.textField}
          type='password'
          onChange={props.handleChange('password')}
          margin='normal'
        />
      </div>
      {!props.sending ? (
        <Button
          variant='contained'
          onClick={props.handleSubmiteInputs(props)}
          component='span'
          className={classes.button}
        >
          Log in
        </Button>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
      {props.error ? (
        <Typography className={classes.errText}>
          {props.message || 'Неправильные данные пользователя'}
        </Typography>
      ) : null}
    </div>
  );
}

const mapStateToProps = ({
  user: { email, password, sending, error, success, userData, message }
}) => ({
  email,
  password,
  sending,
  error,
  success,
  userData,
  message
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleChange,
      handleSubmiteInputs
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorization);
