import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ArrowBack } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../config';
import routes from '../routes';
import PresentationToProductListPicker from './PresentationToProductListPicker';
const productApiUrl = '/api/products';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  icon: {
    margin: theme.spacing(0.5)
  },
  btn: {
    margin: theme.spacing(2)
  }
}));

const EditProduct = ({
  user,
  match: {
    params: { productId }
  }
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    name: ''
  });
  React.useEffect(() => {
    const requestProduct = () => {
      axios
        .get(SERVER_URL + productApiUrl + '/' + productId, {
          headers: { Authorization: `Bearer ${user.token}` }
        })
        .then(({ data }) => {
          setState(data);
        });
    };
    requestProduct();
  }, [productId, user.token]);

  const handleChangeText = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleSavePresentation = (id) => (event) => {
    const { name } = state;

    axios.put(
      SERVER_URL + productApiUrl,
      { target: { id: productId }, props: { name } },
      {
        headers: { Authorization: `Bearer ${user.token}` }
      }
    );
  };

  return (
    <>
      <Link to={routes.manageProducts.link}>
        <Button>
          <Avatar>
            <ArrowBack />
          </Avatar>
        </Button>
      </Link>
      <form className={classes.container}>
        <TextField
          id='standard-name'
          label='Name'
          className={classes.textField}
          value={state.name}
          onChange={handleChangeText('name')}
          margin='normal'
        />
        <Button
          variant='contained'
          className={classes.btn}
          onClick={handleSavePresentation(productId)}
        >
          SAVE <SaveIcon className={classes.icon} />
        </Button>
        <PresentationToProductListPicker productID={productId} />
      </form>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps)(EditProduct);
