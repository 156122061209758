import React from 'react';
import Select from 'react-select';
import './Multiselect.css';

export default function Multiselect({ setSelectedOption, options, selectedOptions }) {
  return (
    <div className='Multiselect'>
      <Select
        isMulti
        key={options.id}
        placeholder={'Выбраны все пользователи. Нажмите для фильтрации'}
        closeMenuOnSelect={false}
        defaultValue={selectedOptions}
        onChange={setSelectedOption}
        options={options}
      />
    </div>
  );
}
