import React, { useState } from 'react';

export default function PresentationPreview({ classes, id, serverUrl }) {
  const [src, setSrc] = useState(`${serverUrl}/${id}/preview/preview_s.jpg`);
  const changeSrcOnError = () => {
    setSrc('./icon.png');
  };
  return (
    <img
      className={classes}
      src={src}
      alt=''
      style={{ background: '#dedede' }}
      height='42.5'
      width='57'
      onError={changeSrcOnError}
    />
  );
}
