import * as axios from 'axios';
import { SERVER_URL } from '../config';
import { activityStatisticsWrite } from '../components/ActivityStatisticsUtils';
export const SEND_LOGIN_FORM = 'user/SEND_LOGIN_FORM';
export const CHANGE_LOGIN_FORM = 'user/CHANGE_LOGIN_FORM';
export const SENDING_LOGIN_FORM_ERROR = 'user/SENDING_LOGIN_FORM_ERROR';
export const SENDING_LOGIN_FORM_FORBIDDEN = 'user/SENDING_LOGIN_FORM_FORBIDDEN';
export const SENDING_LOGIN_FORM_SUCCESS = 'user/SENDING_LOGIN_FORM_SUCCESS';
export const LOGOUT = 'user/LOGOUT';

const logInHref = '/api/authenticate';
const initState = {
  email: '',
  password: '',
  sending: false,
  error: false,
  success: false,
  message: null,
  userData: {}
};

export default (
  state = {
    ...initState,
    ...(window.localStorage.getItem('oncall-state-std')
      ? JSON.parse(window.localStorage.getItem('oncall-state-std'))
      : {})
  },
  action
) => {
  switch (action.type) {
    case SEND_LOGIN_FORM:
      return {
        ...state,
        sending: true
      };

    case CHANGE_LOGIN_FORM:
      return {
        ...state,
        ...action.payload
      };

    case SENDING_LOGIN_FORM_ERROR:
      return {
        ...state,
        sending: false,
        error: true,
        message: 'Неправильные данные пользователя'
      };

    case SENDING_LOGIN_FORM_FORBIDDEN:
      return {
        ...state,
        sending: false,
        error: true,
        message: 'У вас нет доступа к данному ресурсу'
      };

    case SENDING_LOGIN_FORM_SUCCESS:
      const newState = {
        ...state,
        sending: false,
        error: false,
        ...action.payload,
        ...action.payload.user
      };
      addToLocalStorage(newState);
      return newState;

    case LOGOUT: {
      return {
        ...initState
      };
    }
    default:
      return state;
  }
};

export const handleChange = (name) => (dispatch) => (event) => {
  dispatch({ type: CHANGE_LOGIN_FORM, payload: { [name]: event.target.value } });
};

export const handleSubmiteInputs =
  ({ email, password }) =>
  (dispatch) =>
  () => {
    dispatch({ type: SEND_LOGIN_FORM });
    axios
      .post(SERVER_URL + logInHref, {
        email: email,
        password: password /*, date: Date.now(), timeZone: -new Date().getTimezoneOffset()/60 */
      })
      .then((response) => {
        if (response.status === 200) {
          // if (response.data.user.isMCP) return dispatch({ type: SENDING_LOGIN_FORM_FORBIDDEN });
          // else
          return dispatch({ type: SENDING_LOGIN_FORM_SUCCESS, payload: response.data });
        }
      })
      .catch((err) =>
        err.response.status !== 403
          ? dispatch({ type: SENDING_LOGIN_FORM_ERROR })
          : dispatch({ type: SENDING_LOGIN_FORM_FORBIDDEN })
      );
    // .catch(response => dispatch({ type: SENDING_LOGIN_FORM_ERROR, payload: response }));
  };

export const handleLogout = (e) => (dispatch) => {
  e.nativeEvent.preventDefault();
  if (window.localStorage.getItem('oncall-state-std') !== null) {
    window.localStorage.removeItem('oncall-state-std');
    dispatch({ type: LOGOUT });
  }
};

function addToLocalStorage(data) {
  window.localStorage.setItem('oncall-state-std', JSON.stringify(data));
  activityStatisticsWrite('Log In', data.id);
  //document.location.reload(true);
  //return data;
}
