import GroupAdd from '@material-ui/icons/GroupAdd';
import IconGroupWork from '@material-ui/icons/GroupWork';
import Home from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import PersonAdd from '@material-ui/icons/PersonAdd';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Subscriptions from '@material-ui/icons/Subscriptions';
import TableChart from '@material-ui/icons/TableChart';
import IconUsb from '@material-ui/icons/Usb';
import React from 'react';
const phrases = require('./text-constants')();
const access = {
  admin: 'admin'
};

export default {
  homePage: {
    link: '/',
    title: phrases('home'),
    icon: () => <Home />,
    access: [access.admin]
  },
  // apiKeys: {
  //   link: '/api-keys',
  //   title: phrases('APIKeys'),
  //   icon: () => <VpnKey />,
  //   access: [access.admin]
  // },
  presentations: {
    link: '/manage-presentations',
    title: phrases('managePresentations'),
    icon: () => <Subscriptions />,
    access: []
  },
  manageUsers: {
    link: '/manage-users',
    title: phrases('manageUsers'),
    icon: () => <PersonAdd />,
    access: [access.admin]
  },
  addUserGroups: {
    link: '/add-user-groups',
    title: phrases('createAndManageUserGroups'),
    icon: () => <GroupAdd />,
    access: [access.admin]
  },
  manageUserGroup: {
    link: '/manage-user-group',
    title: phrases('userManagementInAGroup'),
    icon: () => <MailIcon />,
    access: [access.admin],
    hide: {
      leftSideMenu: true
    }
  },
  manageUserGroupPres: {
    link: '/manage-user-group-pres',
    title: phrases('userManagementInAGroup'),
    icon: () => <MailIcon />,
    access: [access.admin],
    hide: {
      leftSideMenu: true
    }
  },
  manageProducts: {
    link: '/manage-products',
    title: phrases('manageProducts'),
    icon: () => <IconGroupWork />,
    access: [access.admin]
  },
  editPresentation: {
    link: '/edit-presentation',
    title: '',
    icon: () => <MailIcon />,
    access: [access.admin],
    hide: {
      leftSideMenu: true
    }
  },
  editUser: {
    link: '/edit-user',
    title: '',
    icon: () => <MailIcon />,
    access: [access.admin],
    hide: {
      leftSideMenu: true
    }
  },
  editProduct: {
    link: '/edit-product',
    title: '',
    icon: () => <MailIcon />,
    access: [access.admin],
    hide: {
      leftSideMenu: true
    }
  },
  RESTInfo: {
    link: '/rest-info',
    title: 'API info',
    icon: () => <IconUsb />,
    access: [access.admin]
  },
  bulkAddUser: {
    link: '/bulk-add-users',
    title: 'Bulk add users',
    icon: () => <RecentActorsIcon />,
    access: [access.admin]
  },
  statistic: {
    link: '/statistic',
    title: 'Statistic',
    icon: () => <TableChart />,
    access: [access.admin]
  },
  activityStatistics: {
    link: '/activity-statistics',
    title: 'Activity Statistics',
    icon: () => <TableChart />,
    access: [access.admin]
  }
};
