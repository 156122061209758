import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import counter from './reducers/counter';
import user from './reducers/user';
import leftSideMenu from './reducers/left-side-menu';
import taskList from './reducers/task-list';
export const history = createHashHistory();

const rootReducer = combineReducers({ counter, user, leftSideMenu,taskList });
const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(connectRouter(history)(rootReducer), initialState, composedEnhancers);

export default store;
