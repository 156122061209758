import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { SERVER_URL } from '../config';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  input: { width: 84 },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    maxWidth: 600
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

function SimpleTabs({ token }) {
  const classes = useStyles();
  const [modelsList, setModelsList] = React.useState([]);
  const [value, setValue] = React.useState(0);

  const reqOptions = { headers: { Authorization: `Bearer ${token}` } };

  React.useEffect(() => {
    function requestModels() {
      axios.get(`${SERVER_URL}/rest`, reqOptions).then(({ data }) => setModelsList(data));
    }
    requestModels();
  }, []); // eslint-disable-line

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          aria-label='Simple tabs example'
        >
          {modelsList.map((model, index) => (
            <Tab key={model} label={model} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      {modelsList.map((model, index) => (
        <TabPanel value={value} key={index} index={index}>
          <SchemeModel name={model} token={token} options={reqOptions} />
        </TabPanel>
      ))}
    </div>
  );
}

function SchemeModel({ name, options }) {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [idValue, setIdValue] = React.useState(1);

  const setInputValue = ({ target: { value } }) => setIdValue(value);

  const getById = () => {
    return axios
      .get(`${SERVER_URL}/rest/${name}/${idValue}`, options)
      .then((result) => downloadData(result.data));
  };

  const getAll = () => {
    return axios
      .get(`${SERVER_URL}/rest/${name}/all`, options)
      .then((result) => downloadData(result.data));
  };

  const downloadData = (data) => {
    const href = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
    const link = document.createElement('a');
    link.href = href;
    link.download = 'data.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  React.useEffect(() => {
    function requestModelScheme(name) {
      return axios.get(`${SERVER_URL}/rest/${name}`, options).then(({ data }) => setState(data));
    }
    requestModelScheme(name);
  }, [name, options]);

  return (
    <>
      <Paper square={true} className={classes.paper}>
        <div>Get {name} by ID:</div>
        <Input type='number' value={idValue} onChange={setInputValue} className={classes.input} />
        <Button color='primary' className={classes.button} onClick={getById}>
          Get!
        </Button>
      </Paper>
      <Paper square={true} className={classes.paper}>
        <span>Get all {name}</span>
        <Button color='primary' className={classes.button} onClick={getAll}>
          Get!
        </Button>
      </Paper>
      <code>
        <pre>{JSON.stringify(state, null, ' ')}</pre>
      </code>
    </>
  );
}

const mapStateToProps = ({ user: { token } }) => ({
  token
});

export default connect(mapStateToProps, null)(SimpleTabs);
