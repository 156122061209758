export const UPDATE_TASK_LIST = 'UPDATE_TASK_LIST';

const initState = {
  lastUpdate: new Date().getTime()
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_TASK_LIST:
      return {
        ...state,
        lastUpdate: new Date().getTime()
      };

    default:
      return state;
  }
};

export const actionUpdateTaskList = () => ({
  type: UPDATE_TASK_LIST
});
