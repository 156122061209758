import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { handleOpenMenu } from '../reducers/left-side-menu';
import { bindActionCreators } from 'redux';
import { handleLogout } from '../reducers/user';
import onpoint_logo from '../assets/logo-onpoint.svg';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { activityStatisticsWrite } from './ActivityStatisticsUtils';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    marginTop: theme.spacing(0.5)
  },
  icon: {
    marginLeft: theme.spacing(0.5)
  }
}));

function TopBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            edge='start'
            onClick={props.handleOpenMenu}
            className={classes.menuButton}
            color='inherit'
            aria-label='Menu'
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title}>
            <img src={onpoint_logo} alt='' />
          </Typography>
          {props.user.token ? (
            <>
              <Typography>{`Hello, ${props.user.firstName} ${props.user.secondName}. | `}</Typography>
              <Button 
                onClick={(e) => {
                  props.handleLogout(e)
                  activityStatisticsWrite('Log out', props.user.id)
                }} 
                color='inherit'>
                Logout <ExitToApp className={classes.icon} />
              </Button>
            </>
          ) : (
            <Link to='/login' style={{ color: 'white' }}>
              Login
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
const mapStateToProps = ({ leftSideMenu, user }) => ({
  ...leftSideMenu,
  user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleOpenMenu,
      handleLogout
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
