import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import { SERVER_URL } from '../config';
import { connect } from 'react-redux';
import routes from '../routes';
const userApiUrl = '/api/user';

// @description User model
// User = {
//     firstName: {
//       type: Sequelize.STRING,
//       allowNull: false
//     },
//     lastName: {
//       type: Sequelize.STRING,
//       allowNull: false
//     },
//     secondName: {
//       type: Sequelize.STRING
//     },
//     email: {
//       type: Sequelize.STRING,
//       unique: true,
//       allowNull: false
//     },
//     password: {
//       type: Sequelize.STRING,
//       allowNull: true
//     },
//     isAdmin: {
//       type: Sequelize.BOOLEAN,

//     },
//     isActive: {
//       type: Sequelize.BOOLEAN,
//       defaultValue: true,
//       allowNull: false
//     }
//   }

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  icon: {
    margin: theme.spacing(0.5)
  },
  btn: {
    margin: theme.spacing(2)
  },
  label: {
    marginLeft: 0
  }
}));

const EditUser = ({
  user,
  match: {
    params: { userId }
  }
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    secondName: '',
    email: '',
    isAdmin: false,
    isActive: false,
    isMCP: true,
    hasNeedResetPassword: false,
    newPassword: ''
  });
  React.useEffect(() => {
    const requestUserInfo = () => {
      axios
        .get(SERVER_URL + userApiUrl + '/' + userId, {
          headers: { Authorization: `Bearer ${user.token}` }
        })
        .then(({ data }) => setState(data));
    };
    requestUserInfo();
  }, []); // eslint-disable-line

  const handleChangeText = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleChangeCheckbox = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleSavePresentation = (id) => (event) => {
    const { firstName, lastName, secondName, email, isAdmin, isActive, isMCP } = state;

    const updateProps = {
      firstName,
      lastName,
      secondName,
      email,
      isAdmin,
      isActive,
      isMCP
    };
    if (state.hasNeedResetPassword) {
      updateProps.password = state.newPassword;
    }
    axios
      .put(
        SERVER_URL + userApiUrl,
        { target: { id: userId }, updateProps },
        {
          headers: { Authorization: `Bearer ${user.token}` }
        }
      )
      .then(() => setState({ ...state, hasNeedResetPassword: false, newPassword: '' }));
  };

  return (
    <form className={classes.container}>
      <Link to={routes.manageUsers.link}>
        <Button>
          <Avatar>
            <IconArrowBack />
          </Avatar>
        </Button>
      </Link>
      <TextField
        id='standard-name'
        label='firstName'
        className={classes.textField}
        value={state.firstName}
        onChange={handleChangeText('firstName')}
        margin='normal'
      />
      <TextField
        id='standard-name'
        label='secondName'
        className={classes.textField}
        value={state.secondName}
        onChange={handleChangeText('secondName')}
        margin='normal'
      />
      <TextField
        id='standard-name'
        label='lastName'
        className={classes.textField}
        value={state.lastName}
        onChange={handleChangeText('lastName')}
        margin='normal'
      />
      <TextField
        id='standard-name'
        label='email'
        className={classes.textField}
        value={state.email}
        onChange={handleChangeText('email')}
        margin='normal'
      />
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={state.isAdmin}
            onChange={handleChangeCheckbox('isAdmin')}
            value='isAdmin'
            color='primary'
          />
        }
        label='isAdmin'
      />
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={state.isActive}
            onChange={handleChangeCheckbox('isActive')}
            value='isActive'
          />
        }
        label='isActive'
      />
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            checked={state.hasNeedResetPassword}
            onChange={handleChangeCheckbox('hasNeedResetPassword')}
            value='hasNeedResetPassword'
          />
        }
        label='hasNeedResetPassword'
      />
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox checked={state.isMCP} onChange={handleChangeCheckbox('isMCP')} value='isMCP' />
        }
        label='isMCP'
      />
      {state.hasNeedResetPassword ? (
        <TextField
          id='standard-name'
          label='newPassword'
          className={classes.textField}
          value={state.newPassword}
          onChange={handleChangeText('newPassword')}
          margin='normal'
        />
      ) : null}
      <Button variant='contained' className={classes.btn} onClick={handleSavePresentation(userId)}>
        SAVE <SaveIcon className={classes.icon} />
      </Button>
    </form>
  );
};

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps)(EditUser);
