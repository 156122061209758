import * as axios from 'axios';
import { SERVER_URL } from '../config';

export const activityStatisticsWrite = (action, id) => {
  if (!id)
    id =
      window.localStorage.getItem('oncall-state-std') &&
      JSON.parse(window.localStorage.getItem('oncall-state-std')).id;
  const timeZone = -new Date().getTimezoneOffset() / 60;
  const date = Date.now();
  if (id) {
    const UserId = id;
    return axios
      .post(SERVER_URL + '/api/activity-statistics/', { UserId, action, date, timeZone })
      .catch((err) => console.log(err));
  }
};
